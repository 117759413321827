import { CookiesService } from "@/services"
import NotificationStore from "@/store/notification"
import { Button, Snackbar } from "@mui/material"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

const SnackbarNewVersion = () => {
    const { show, update } = NotificationStore()
    const handleOnClose = useCallback(() => {
        update({ message: "" })
    }, [update])
    const { t } = useTranslation()
    const resetCookies = useCallback(() => {
        CookiesService.clearOldCookies()
        window.location.reload()
    }, [CookiesService])

    return (
        <Snackbar
            open={show}
            sx={{
                "& .MuiSnackbarContent-root": {
                    flexDirection: "row",
                    backgroundColor: "rgb(0, 4, 31)",
                },
            }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            message={t("COMMON.NEW_APP_VERSION")}
            action={
                <Button color="secondary" size="small" onClick={resetCookies}>
                    {t("COMMON.RELOAD")}
                </Button>
            }
            onClose={() => handleOnClose()}
            ClickAwayListenerProps={{
                onClickAway: () => null,
            }}
        />
    )
}

export default SnackbarNewVersion
