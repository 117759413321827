import { ResellerApi } from "@/api"
import useFormater from "@/hooks/useFormater"
import useLocationStore from "@/store/location"
import useBusinessCategoriesStore from "@/store/overview/categories"
import useLinksStore from "@/store/overview/links"
import useBusinessServicesStore from "@/store/overview/services"
import useBusinessServicesAreaStore from "@/store/overview/services-area"
import UiStore from "@/store/ui"
import { generateUrl } from "@/utils/auth"
import { useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

const useLocation = (page?: string) => {
    const { location, needReconnexion, isLoading, update } = useLocationStore()
    const { updateServices } = useBusinessServicesStore()
    const { updateServicesArea } = useBusinessServicesAreaStore()
    const { websiteUrl, linksNetwork } = useLinksStore()
    const { updateCategories, getInitialState, primaryCategory, secondaryCategory } = useBusinessCategoriesStore()
    const { preferredLanguage } = UiStore()
    const { cleanUrl } = useFormater()
    const { t } = useTranslation()

    const authUrl = useMemo(() => {
        if (!location || !needReconnexion) {
            return ""
        }
        return generateUrl({
            ag_uid: location?.companyId,
            gmb_access_id: location?.gmbAccessId,
        })
    }, [needReconnexion, location])

    const scheduleStatus = useMemo(() => {
        return [
            { label: t("BUSINESSES.SCHEDULE.OPEN_STANDARD_HOURS"), value: "OPEN" },
            { label: t("BUSINESSES.SCHEDULE.OPEN_WITHOUT_STANDARD_HOURS"), value: "OPEN_WITHOUT_STANDARD_HOURS" },
            { label: t("BUSINESSES.SCHEDULE.TEMPORARILY_CLOSED"), value: "CLOSED_TEMPORARILY" },
            { label: t("BUSINESSES.SCHEDULE.PERMANENTLY_CLOSED"), value: "CLOSED_PERMANENTLY" },
        ]
    }, [t])

    const serviceTypeIdsMap = useMemo(() => {
        const serviceTypeIdMap = {}
        if (primaryCategory) {
            primaryCategory?.serviceTypes?.forEach((service) => {
                serviceTypeIdMap[service.serviceTypeId] = service.displayName
            })
        }
        if (secondaryCategory) {
            secondaryCategory?.forEach((category) => {
                category?.serviceTypes?.forEach((item) => {
                    serviceTypeIdMap[item.serviceTypeId] = item.displayName
                })
            })
        }
        return serviceTypeIdMap
    }, [primaryCategory, secondaryCategory])

    const fetchPrimaryCategory = useCallback(
        async (id = "") => {
            return await ResellerApi.fetchPrimaryCategory({ id, lang: preferredLanguage })
        },
        [preferredLanguage]
    )

    const fetchSecondaryCategory = useCallback(
        async (ids = []) => {
            return await ResellerApi.fetchSecondaryCategory({ ids, lang: preferredLanguage })
        },
        [preferredLanguage]
    )

    const fetchAttribute = useCallback(
        async (id = "") => {
            let defaultLang = preferredLanguage
            switch (preferredLanguage?.toLowerCase()) {
                case "fr-fr":
                    defaultLang = "fr"
                    break
                case "fr-ca":
                    defaultLang = "ca"
                    break
            }
            return await ResellerApi.fetchLocationAttribute({
                locationId: id,
                company_id: location?.companyId,
                lang: defaultLang,
            })
        },
        [location?.companyId, preferredLanguage]
    )

    const getCategories = useCallback(
        async (categories) => {
            let primaryCategory = {
                id: "",
                label: "",
                serviceTypes: [],
            }
            let secondaryCategory = []
            let facebookCategory = []
            if (categories?.primary) {
                const response: any = await fetchPrimaryCategory(categories?.primary)
                if (response) {
                    primaryCategory = response
                }
            }
            const otherCagetoriesIds = categories?.secondary || []
            if (categories?.facebook) {
                otherCagetoriesIds.push(...(categories.facebook || []))
            }
            if (otherCagetoriesIds?.length > 0) {
                const response = (await fetchSecondaryCategory(otherCagetoriesIds)) ?? []
                if (!response) return
                const secondaryList = response?.filter((res) => !res.meta?.facebook_id)
                const facebookCategoryList = response?.filter((res) => res.meta?.facebook_id)
                if (response) {
                    secondaryCategory = secondaryList || []
                    facebookCategory = facebookCategoryList || []
                }
            } else {
                secondaryCategory = []
                facebookCategory = []
            }
            return {
                primaryCategory,
                secondaryCategory,
                facebookCategory,
            }
        },
        [fetchPrimaryCategory, fetchSecondaryCategory]
    )

    const setCategories = useCallback(
        async (categories, { isDraft }) => {
            const categoriesState = getInitialState()
            if (!isDraft) {
                categoriesState.categories = categories
                const { primaryCategory, secondaryCategory, facebookCategory } = await getCategories(categories)
                categoriesState.primaryCategory = primaryCategory
                categoriesState.secondaryCategory = secondaryCategory
                categoriesState.facebookCategory = facebookCategory
                categoriesState.categoriesDraft = null
            } else {
                categoriesState.categoriesDraft = categories
            }
            updateCategories({ ...categoriesState })
        },
        [getCategories, updateCategories]
    )

    const updateLocation = useCallback(
        async (location) => {
            const updatedState: any = {
                location: { ...location },
                currentLocationId: location.id,
            }
            update({ ...updatedState, isLoading: false })
            updateCategories({ categoriesDraft: null })
            updateServicesArea({ servicesAreaDraft: null })
        },
        [update]
    )

    const resetLocation = useCallback(() => {
        update({
            location: {},
            currentLocationId: "",
            // services: {},
        })
        updateServices({ services: {} })
    }, [update])

    useEffect(() => {
        if (location?.locationState) {
            update({
                needReconnexion:
                    location?.locationState?.needsReconnection || location?.locationState?.locationNotFound,
            })
        }
    }, [location?.locationState?.needsReconnection, location?.locationState?.locationNotFound, update])

    return {
        location,
        websiteUrl: cleanUrl(websiteUrl),
        authUrl,
        needReconnexion,
        isLoading,
        serviceTypeIdsMap,
        scheduleStatus,
        resetLocation,
        updateLocation,
        fetchPrimaryCategory,
        fetchSecondaryCategory,
        fetchAttribute,
        setCategories,
        getCategories,
        linksNetwork,
        updateLoading: (isLoading: boolean) => update({ isLoading }),
    }
}

export default useLocation
