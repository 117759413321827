import Icon from "@/components/base/Icon"
import SocialPopupStore from "@/store/social-popup"
import { Alert, AlertTitle, Collapse, IconButton, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const AlertPostError = () => {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation()
    const { showPopupContent } = SocialPopupStore()

    const handleShowDetailsError = (e) => {
        e.preventDefault()
        showPopupContent("error_details")
    }

    return (
        <Collapse in={open}>
            <Alert
                sx={{ flexDirection: "row", gap: 1 }}
                severity="error"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        <Icon name="close" />
                    </IconButton>
                }
            >
                <AlertTitle>{t("SOCIALS.ALERT_POST_ERROR.TITLE")}</AlertTitle>
                <Stack gap={1}>
                    <Typography variant="body2" color={"#663C00"}>
                        {t("SOCIALS.ALERT_POST_ERROR.DESCRIPTION")}
                    </Typography>
                    <Link to="#" onClick={handleShowDetailsError} style={{ color: "#5F2120" }}>
                        <Typography
                            variant="body2"
                            fontFamily={"Poppins Medium "}
                            fontSize={13}
                            color={"#5F2120"}
                            fontWeight={"bold"}
                        >
                            {t("SOCIALS.SHOW_DETAILS")}
                        </Typography>
                    </Link>
                </Stack>
            </Alert>
        </Collapse>
    )
}
