import UiStore from "@/store/ui"
import data from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import { Button, CircularProgress, IconButton, Popover, Stack } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../base/Icon"
import BaseInput from "../base/input"
import useStyles, { menuSx } from "./styles"

interface InputEmojiCustomProps {
    text: string
    isModify: boolean
    responseByAi: boolean
    loadingAI: boolean
    isLoading: boolean
    maxLength: number
    isLimitExceeded: boolean
    suggest?: boolean
    displayBtn?: boolean
    handleSave?: (value: string, isByAi: boolean) => void | null
    handleCancel?: () => void
    handleSuggest?: () => void
    setText: any
    placeholder?: string
    disabled?: boolean
}

const InputEmojiCustom = ({
    text,
    setText,
    handleSave,
    isModify,
    responseByAi = false,
    handleCancel,
    loadingAI = false,
    isLoading = false,
    maxLength,
    handleSuggest,
    isLimitExceeded,
    suggest = true,
    displayBtn = true,
    placeholder,
    disabled,
}: InputEmojiCustomProps) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { preferredLanguage } = UiStore()
    const lengthError = useMemo(() => {
        return !text
    }, [text])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])
    const [isByAi, setIsByAi] = useState<boolean>(Boolean(responseByAi))
    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const toggleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(!open ? event.currentTarget : null)
        },
        [setAnchorEl, open]
    )

    const handlePicker = useCallback(
        (e) => {
            let prev = text
            if (prev.length <= 4096 - 2) {
                prev = prev + e.native
            }
            setText(prev)
        },
        [setText, text]
    )

    useEffect(() => {
        if (text === "") {
            setIsByAi(false)
        }
    }, [text])

    const suggestIA = useCallback(() => {
        handleSuggest()
        setIsByAi(true)
    }, [isByAi, text])

    return (
        <Stack className={classes.blocInputEmoji}>
            <Stack className={classes.emojiInput} data-e2e="review-replay-input">
                <BaseInput
                    multiline
                    helperText={`${text?.length || "0"}/4096`}
                    helperTextPosition="right"
                    value={text}
                    placeholder={placeholder ?? t("REPUTATION.PLACEHOLDER_ANSWER")}
                    max={maxLength}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled}
                />
                <Stack className="section-emoji">
                    <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                        <Stack className="bloc-emoji">
                            <IconButton onClick={toggleMenu} className="btn-x" disabled={disabled}>
                                <Icon name="emoji" sx={{ fill: "none" }} />
                            </IconButton>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                id={"basic-menu"}
                                sx={menuSx}
                            >
                                <Stack className="bloc-emoji">
                                    <Picker
                                        data={data}
                                        locale={preferredLanguage.split("-")[0]}
                                        onEmojiSelect={handlePicker}
                                    />
                                </Stack>
                            </Popover>
                        </Stack>
                        {!isModify && suggest && (
                            <Button
                                variant={!isLimitExceeded ? "contained" : "text"}
                                className={!isLimitExceeded && "btn-generate"}
                                onClick={() => {
                                    suggestIA()
                                }}
                                disabled={isLimitExceeded}
                            >
                                {loadingAI && <CircularProgress size={16} sx={{ width: 20, height: 20 }} />}
                                {!loadingAI && (
                                    <Icon name="doubleStar" sx={{ width: 20, height: 20, color: "#8311D7" }} />
                                )}
                                {t("REPUTATION.SUGGEST")}
                            </Button>
                        )}
                    </Stack>

                    <Stack className="btn-input-emoji">
                        {isModify && (
                            <>
                                <Button
                                    variant={"contained"}
                                    className="btn-cancel"
                                    onClick={() => {
                                        if (typeof handleCancel === "function") {
                                            handleCancel()
                                        }
                                    }}
                                    data-e2e="cancel-review"
                                    disabled={isLoading}
                                >
                                    {t("REPUTATION.CANCEL")}
                                </Button>
                                <Button
                                    variant={"contained"}
                                    onClick={() => {
                                        if (typeof handleSave === "function") {
                                            handleSave(text, isByAi)
                                        }
                                    }}
                                    disabled={lengthError}
                                    data-e2e="save-review"
                                >
                                    {isLoading && <CircularProgress size={16} sx={{ width: 20, height: 20 }} />}
                                    {!isLoading && <Icon name="save" sx={{ width: 20, height: 20 }} />}
                                    {t("REPUTATION.SAVE")}
                                </Button>
                            </>
                        )}
                        {!isModify && displayBtn && (
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    if (typeof handleSave === "function") {
                                        handleSave(text, isByAi)
                                    }
                                }}
                                disabled={lengthError}
                                data-e2e="send-review"
                            >
                                {isLoading && <CircularProgress size={16} sx={{ width: 20, height: 20 }} />}
                                {!isLoading && <Icon name="send" sx={{ width: 20, height: 20 }} />}
                                {t("REPUTATION.SEND")}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default InputEmojiCustom
