import { ApiService } from "@/api"
import Icon from "@/components/base/Icon"
import env from "@/config/env"
import { modalStyle } from "@/pages/users/styles"
import { isGoogleConnected, withSxProp } from "@/utils"
import { openGoogleAccount } from "@/utils/auth"
import { Box, Button, Modal, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

const ReconnectGoogleModal = ({ popupContainer, open = false, location, user, onClose }) => {
    const [openModal, setOpenModal] = useState(false)
    const { t } = useTranslation()
    const { classes } = useStyles()

    const needsReconnection = useMemo(() => {
        return location?.locationState?.needsReconnection
    }, [location?.locationState?.needsReconnection])

    const linkGoogleDoc = useMemo(() => {
        return `${env.DOCS_LINK}/${t("BUSINESSES.RECONNECT_GOOGLE.DOC_LINK")}`
    }, [t])

    const handleConnectButton = async () => {
        openGoogleAccount({
            companyId: location?.companyId,
            user_id: user?.uid,
            gmb_access_id: location?.gmbAccessId,
            yext_id: location?.meta?.yext_id,
            locationName: location?.meta?.gmb_id,
            bearer: ApiService.getToken()?.token,
        })
    }

    useEffect(() => {
        setOpenModal(open)
    }, [open])

    return (
        <Modal className={classes.container} open={openModal} container={() => popupContainer?.current}>
            <Paper sx={withSxProp(modalStyle, { maxWidth: 440, boxSizing: "border-box" })}>
                {needsReconnection && !isGoogleConnected(location) && (
                    <>
                        <Typography
                            variant="h1"
                            component={"span"}
                            data-e2e="popup-need-reco"
                            sx={{ fontSize: 24, textAlign: "left", width: "100%" }}
                        >
                            {t("BUSINESSES.RECONNECT_GOOGLE.TITLE_ONE")}
                        </Typography>
                        <Typography sx={{ color: "action.active", fontSize: 14 }}>
                            {t("BUSINESSES.RECONNECT_GOOGLE.MANDATORY")}
                        </Typography>
                        <Box className="action-wrapper">
                            <Button onClick={onClose}>{t("REPUTATION.CANCEL")}</Button>
                            <Button
                                onClick={handleConnectButton}
                                variant="contained"
                                size="large"
                                sx={{ gap: 1, width: "100%" }}
                            >
                                <Icon name="unicolorGoogle" />
                                {t("BUSINESSES.RECONNECT_GOOGLE.TITLE_BUTTON")}
                            </Button>
                        </Box>
                    </>
                )}

                {!needsReconnection && !isGoogleConnected(location) && (
                    <>
                        <Typography
                            variant="h1"
                            component={"span"}
                            data-e2e="popup-reco-failed"
                            sx={{ fontSize: 24, textAlign: "left", width: "100%" }}
                        >
                            {t("BUSINESSES.RECONNECT_GOOGLE.ERROR")}
                        </Typography>
                        <Typography sx={{ color: "action.active" }}>{t("BUSINESSES.RECONNECT_GOOGLE.FAQ")}</Typography>
                        <Stack sx={{ width: "100%", gap: 1 }}>
                            <Button color="secondary" size="large" className="btn" onClick={onClose}>
                                {t("REPUTATION.CANCEL")}
                            </Button>
                            <Button color="secondary" size="large" className="btn" onClick={handleConnectButton}>
                                {t("BUTTON.RETRY")}
                            </Button>
                            <Button
                                href={linkGoogleDoc}
                                target="blank"
                                variant="contained"
                                size="large"
                                sx={{ width: "100%", boxSizing: "border-box" }}
                            >
                                {t("BUSINESSES.RECONNECT_GOOGLE.DOC")}
                            </Button>
                        </Stack>
                    </>
                )}
            </Paper>
        </Modal>
    )
}

export default ReconnectGoogleModal
