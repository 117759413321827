import { Checkbox, Chip, IconButton, SelectProps, Stack, Typography } from "@mui/material"
import SelectBase from "../select-base"
import { useCallback, useEffect, useMemo, useState } from "react"
import Icon from "@/components/base/Icon"

type Options = {
    value: string | number
    label: string
    description?: string
}

type SelectCheckboxProps = {
    options: Options[]
    onClear?: () => void
}

const SelectCheckbox = ({ label, options, onClear, ...props }: SelectCheckboxProps & SelectProps) => {
    const [selected, setSelected] = useState<any>([])

    const onDelete = useCallback((id: string) => {
        setSelected((prev: any) => prev.filter((value: any) => value !== id))
    }, [])

    const optionMap = useMemo(() => {
        const map = {}
        options.forEach((option) => {
            map[option.value] = option
        })
        return map
    }, [options])

    useEffect(() => {
        setSelected(props?.value || [])
    }, [props?.value])

    return (
        <SelectBase
            id="select-checkbox"
            label={label}
            value={selected || []}
            multiple
            endAdornment={
                selected.length > 0 ? (
                    <IconButton
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                            setSelected([])
                            onClear()
                        }}
                    >
                        <Icon name="close" sx={{ width: 18, height: 18 }} />
                    </IconButton>
                ) : null
            }
            options={options || []}
            onChange={(event) => {
                setSelected(event.target.value)
            }}
            MenuProps={{
                sx: {
                    "& .MuiPaper-root": {
                        padding: 0,
                        maxHeight: 200,
                    },
                    "& .MuiMenuItem-root": {
                        padding: 0.5,
                    },
                },
            }}
            renderValue={(selected: string[]) => (
                <Stack flexDirection={"row"} gap={0.75} sx={{ zIndex: 1 }} flexWrap={"wrap"}>
                    {selected.map((value) => (
                        <Chip
                            key={value}
                            onDelete={() => onDelete(value)}
                            label={optionMap[value]?.label}
                            sx={{
                                backgroundColor: "common.black",
                                color: "common.white",
                                "& .MuiChip-deleteIcon": {
                                    color: "#FFFFFF !important",
                                    opacity: 0.7,
                                },
                            }}
                            onMouseDown={(event) => {
                                event.stopPropagation()
                            }}
                        />
                    ))}
                </Stack>
            )}
            renderOptions={(option: Options) => (
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Checkbox size="small" checked={selected.includes(option.value)} />
                    <Stack>
                        <Typography>{option.label}</Typography>
                        {option.description && (
                            <Typography
                                variant="h6"
                                fontSize={"12px"}
                                component={"span"}
                                sx={{ color: "action.active", fontWeight: 600 }}
                            >
                                {option.description}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            )}
            {...props}
        />
    )
}

export default SelectCheckbox
