import { ResellerApi } from "@/api"
import Icon from "@/components/base/Icon"
import { CookiesService } from "@/services"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { Button, CircularProgress, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { AlertModalCreationBusiness } from "./alert-creation"
import { menuSx } from "./schedule/action-btn/styles"
import useResponsive from "@/hooks/use-responsive"

const EstablishmentHeader = ({
    anchorEl,
    faceboookMenuHandler,
    handleCloseFacebookMenu,
    openFacebookMenu,
    authUrl,
    handeleConnect,
    isLoggedFacebook,
    disconnectSocial,
    isGoogleConnected,
    handleConnectGoogle,
    isLoggedInstagram,
    isSingleBusiness = false,
    disconnectProvider = "facebook",
}) => {
    const { profile, isAdminCompany } = UserSessionStore()
    const { updateState: setUiPageSession } = UiPageSessionStore()
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const { t } = useTranslation()
    const [loadingCheckLicence, setLoadingCheckLicence] = useState(false)
    const { isMobile } = useResponsive()

    const hanldeModalAlert = (value: boolean) => {
        setOpenAlert(value)
    }

    const onClickButton = useCallback(async () => {
        setLoadingCheckLicence(true)
        const res = await ResellerApi.checkLicence({
            uid: profile.uid,
            reseller_uid: CookiesService.get("resellerUid"),
        })
        if (res?.has_price) {
            hanldeModalAlert(true)
        } else {
            setUiPageSession({ showPage: "create" })
        }
        setLoadingCheckLicence(false)
    }, [profile.uid, setUiPageSession])

    return (
        <Paper className={`social-wrapper ${isSingleBusiness && isAdminCompany && "space-between"}`}>
            {isSingleBusiness && isAdminCompany && (
                <Button className="action-btn" color="primary" variant="contained" onClick={onClickButton}>
                    {loadingCheckLicence && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                    {!loadingCheckLicence && <Icon name={"plus"} />}
                    <Typography data-testid="user-button" component="span">
                        {t("BUSINESSES.CREATE_BUTTON")}
                    </Typography>
                </Button>
            )}
            <Stack gap={isMobile ? 1 : 2} flexDirection="row" flexWrap={"wrap"}>
                <Button
                    color={isLoggedFacebook ? "success" : "primary"}
                    className="social-btn"
                    variant="contained"
                    onClick={(e) => (!isLoggedFacebook ? handeleConnect("facebook") : faceboookMenuHandler(e))}
                    id="basic-button"
                    aria-controls={openFacebookMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    data-testid="facebook"
                    aria-expanded={openFacebookMenu ? "true" : undefined}
                >
                    <Icon name="unicolorFacebook" />
                    <Typography component={"span"}>
                        {isLoggedFacebook ? t("BUSINESSES.CONNECTED") : t("BUSINESSES.CONNECT")}
                    </Typography>
                </Button>
                <Button
                    color={isLoggedInstagram ? "success" : "primary"}
                    className="social-btn"
                    variant="contained"
                    onClick={(e) =>
                        !isLoggedInstagram ? handeleConnect("instagram") : faceboookMenuHandler(e, "instagram")
                    }
                    id="basic-button"
                    aria-haspopup="true"
                    data-testid="instagram"
                >
                    <Icon name="instagramUniColor" sx={{ color: "white" }} />
                    <Typography component={"span"}>
                        {isLoggedInstagram ? t("BUSINESSES.CONNECTED") : t("BUSINESSES.CONNECT")}
                    </Typography>
                </Button>
                <Button
                    color={isGoogleConnected ? "success" : "primary"}
                    className="social-btn"
                    variant="contained"
                    href={authUrl}
                    data-testid="google"
                >
                    <Icon name="unicolorGoogle" />
                    <Typography
                        component={"span"}
                        onClick={() => {
                            if (!isGoogleConnected) {
                                handleConnectGoogle()
                            }
                        }}
                    >
                        {isGoogleConnected ? t("BUSINESSES.CONNECTED") : t("BUSINESSES.RECONNECT_GOOGLE.TITLE_BUTTON")}
                    </Typography>
                </Button>

                <Menu
                    sx={menuSx}
                    id="basic-menu"
                    data-testid="menu"
                    anchorEl={anchorEl}
                    open={openFacebookMenu}
                    onClose={handleCloseFacebookMenu}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem
                        data-e2e="disconnect-facebook"
                        data-testid="disconnect"
                        onClick={() => {
                            disconnectSocial()
                        }}
                    >
                        {t("BUSINESSES.DISCONNECT_SOCIAL_MEDIA.MENU")}
                    </MenuItem>
                </Menu>
            </Stack>
            <AlertModalCreationBusiness
                open={openAlert}
                onClose={() => hanldeModalAlert(false)}
                onNext={() => setUiPageSession({ showPage: "create" })}
            />
        </Paper>
    )
}

export default EstablishmentHeader
