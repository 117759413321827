import BaseInput from "@/components/base/input"
import useLocationStore from "@/store/location"
import UiPageSessionStore from "@/store/ui-page-session"
import { SelectType } from "@/types/select"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import ClearIcon from "@mui/icons-material/Clear"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import { Autocomplete, Checkbox, Divider, Stack, Typography, createFilterOptions } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useBusinessHooks from "./useBusinessHooks"
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const indeterminateIcon = (
    <IndeterminateCheckBoxIcon
        fontSize="small"
        style={{
            color: "#0180FF",
        }}
    />
) // Dash icon

interface BusinessAutocompleteProps {
    label?: string | null
    defaultValue?: string[] | string
    selectionChange?: (data: SelectType | SelectType[]) => void
    sx?: any
    disabled?: boolean
    fields?: string[]
    multiple?: boolean
    filterAddress?: boolean
}

const BusinessAutocomplete = ({
    label = null,
    defaultValue = [],
    selectionChange,
    disabled = false,
    fields = [
        "uid",
        "meta",
        "id",
        "logo",
        "address",
        "zip",
        "region",
        "country",
        "locationState",
        "newReviewUri",
        "companyId",
        "name",
        "gmbAccessId",
        "address",
        "city",
        "serviceArea",
    ],
    multiple = true,
    filterAddress = false,
    sx,
}: BusinessAutocompleteProps) => {
    const { businesses, update } = useLocationStore()
    const { t } = useTranslation()

    const { menuPage } = UiPageSessionStore()
    const { fetchBusinessList } = useBusinessHooks()
    const FETCH_BUSINESS_PAGES = useMemo(() => {
        return ["reputation", "listing", "local_presence", "campaign", "socials", "users"]
    }, [])
    // const [focused, setFocused] = useState(false)
    const [selectedValues, setSelectedValues] = useState<SelectType[] | SelectType>([])
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [isIndeterminate, setIsIndeterminate] = useState(false)
    const [selectChanged, setSelectChanged] = useState(false)

    const filterOptions = useCallback((options, state) => {
        // Filter out the "Select All" option
        const filteredOptions = createFilterOptions({
            matchFrom: "any",
            stringify: (option: SelectType) => `${option.label} ${option.shortAddress}`,
        })(
            options.filter((option) => option.label !== "AUTOCOMPLETE.SELECT_ALL"),
            state
        )

        // Keep the "Select All" option at the top
        const selectAllOption = options.find((option) => option.label === "AUTOCOMPLETE.SELECT_ALL")

        if (selectAllOption) {
            return [selectAllOption, ...filteredOptions]
        }

        return filteredOptions
    }, [])

    useEffect(() => {
        if (businesses) {
            if (multiple && typeof defaultValue === "object") {
                const filteredBusinesses = businesses?.filter((business) =>
                    defaultValue.includes(String(business.value))
                )
                setSelectedValues(filteredBusinesses)
                return
            }
            if (!multiple && typeof defaultValue === "string") {
                const filteredBusinesses = businesses?.filter((business) => defaultValue === String(business.value))
                if (filteredBusinesses?.length === 1) {
                    setSelectedValues(filteredBusinesses[0])
                } else {
                    setSelectedValues(null)
                }
            }
        }
    }, [businesses, multiple, defaultValue])

    useEffect(() => {
        if (!businesses && FETCH_BUSINESS_PAGES.includes(menuPage)) {
            fetchBusinessList(fields)
        }
    }, [fetchBusinessList, businesses, FETCH_BUSINESS_PAGES, menuPage])

    const handleSelectAll = useCallback(
        (checked: boolean) => {
            if (!businesses) return
            setSelectedValues(checked ? businesses.filter((item) => item.shortAddress.trim() !== "") : [])
        },
        [businesses]
    )

    const handleChange = useCallback(
        (event, newValue) => {
            const selectAll =
                newValue === null || newValue?.length !== undefined
                    ? newValue.filter((item: SelectType) => item.label === "AUTOCOMPLETE.SELECT_ALL")
                    : []
            setSelectChanged(true)
            if (selectAll?.length) {
                const checked = !isAllSelected

                setIsAllSelected(checked)
                handleSelectAll(checked)
                setIsIndeterminate(false)

                return
            }
            setSelectedValues(newValue)
            if (!multiple) {
                return
            }
            if (newValue.length === businesses.length) {
                setIsAllSelected(true)
                setIsIndeterminate(false)
            } else if (newValue.length > 0) {
                setIsAllSelected(false)
                setIsIndeterminate(true)
            } else {
                setIsAllSelected(false)
                setIsIndeterminate(false)
            }
        },
        [multiple, businesses, isAllSelected]
    )

    const handleBlurEvent = useCallback(() => {
        if (typeof selectionChange === "function" && selectChanged) {
            selectionChange(selectedValues)
            setSelectChanged(false)
        }
    }, [selectionChange, selectedValues, selectChanged, multiple])

    const options = useMemo(() => {
        if (!businesses) return []
        const businessList = [
            ...(multiple
                ? [
                      {
                          label: "AUTOCOMPLETE.SELECT_ALL",
                          value: "",
                          shortAddress: "",
                      },
                  ]
                : []),
            ...businesses.filter((item) => item.shortAddress.trim() !== ""),
        ]
        if (filterAddress) return businessList
        return businessList
    }, [businesses, multiple, filterAddress])

    useEffect(() => {
        return () => {
            setSelectedValues([])
            setIsAllSelected(false)
            setIsIndeterminate(false)
            setSelectChanged(false)
            // update({ businesses: null })
        }
    }, [setSelectedValues])

    return (
        <Stack
            sx={{
                ...sx,
                ...{
                    padding: 0, // Removes the padding
                    "@media(max-width: 1023px)": {
                        minWidth: "100%",
                    },
                    "& .MuiChip-root:nth-child(n+2)": {
                        display: "none",
                    },
                    "& .MuiAutocomplete-root": {
                        width: "auto",
                    },
                },
            }}
        >
            {businesses?.length > 1 && (
                <Autocomplete
                    multiple={multiple}
                    id="checkboxes-tags-business-autocomplete"
                    options={options}
                    filterOptions={filterOptions}
                    disableCloseOnSelect
                    limitTags={1}
                    value={selectedValues}
                    clearIcon={multiple ? <ClearIcon /> : null}
                    onChange={handleChange}
                    disabled={!businesses || disabled}
                    getOptionLabel={(option) => option.label}
                    renderTags={(tagValue, getTagProps) => {
                        const tag = tagValue.filter((item) => item.value !== "")
                        return (
                            tag.length > 0 &&
                            !!tag[0]?.value && (
                                <>
                                    {multiple && (
                                        <Typography
                                            key={`${tag[0].value}-chip`}
                                            sx={{
                                                maxWidth: "50%",
                                                display: "flex",
                                                ...(disabled && {
                                                    "-webkit-text-fill-color": "rgba(104, 115, 141, 0.64)",
                                                }),
                                            }}
                                        >
                                            <Typography component={"span"} className="ellipse">
                                                {t(tag[0].label.slice(0, 8))}
                                            </Typography>

                                            {tagValue.length > 1 ? "+" + (tagValue.length - 1) : ""}
                                        </Typography>
                                    )}
                                    {!multiple && (
                                        <Typography
                                            key={`${tag[0].value}-chip`}
                                            className="ellipse"
                                            sx={{
                                                ...(disabled && {
                                                    "-webkit-text-fill-color": "rgba(104, 115, 141, 0.64)",
                                                }),
                                            }}
                                        >
                                            {t(tag[0].label)}
                                        </Typography>
                                    )}
                                </>
                            )
                        )
                    }}
                    renderOption={(props, option, { selected }) => {
                        if (option.label === "AUTOCOMPLETE.SELECT_ALL" && multiple) {
                            return (
                                <Stack key={`select-all-option`}>
                                    <li {...props}>
                                        <Checkbox
                                            id="select-all"
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            indeterminateIcon={indeterminateIcon} // Add indeterminate icon
                                            indeterminate={isIndeterminate} // Set indeterminate state
                                            style={{ marginRight: 8 }}
                                            checked={isAllSelected}
                                        />
                                        {t(option.label)}
                                    </li>
                                    <Divider style={{ width: "100%" }} /> {/* Separator */}
                                </Stack>
                            )
                        }
                        return (
                            <li key={`${option.value}`} {...props}>
                                {multiple && (
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                )}

                                <Stack key={`${option.value}-stack`}>
                                    <Typography>{option.label}</Typography>
                                    {option.shortAddress && (
                                        <Typography
                                            variant="h6"
                                            fontSize={"12px"}
                                            component={"span"}
                                            sx={{ color: "action.active", fontWeight: 600 }}
                                        >
                                            {option.shortAddress}
                                        </Typography>
                                    )}
                                </Stack>
                            </li>
                        )
                    }}
                    onBlur={handleBlurEvent}
                    renderInput={(params) => {
                        return (
                            <BaseInput
                                {...params}
                                disabled={disabled}
                                variant="filled"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off", // Forcer une désactivation plus robuste de l'autocomplete car le navigateur semble ignorer le autocomplete off
                                }}
                                label={label || t("MENU.BUSINESSES")}
                                placeholder={""}
                            />
                        )
                    }}
                />
            )}
        </Stack>
    )
}

export default BusinessAutocomplete
