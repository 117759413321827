import { CampaignApi } from "@/api"
import { SendCampaignProps } from "@/api/campaign"
import { CookiesService, EditModelService } from "@/services"
import CampaignStore from "@/store/campaign"
import CampaignReviewsStore from "@/store/campaign-reviews"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { IListReviewsClient } from "@/types/campaign"
import { convertHtml, groupByKey, removeDuplicateUrl } from "@/utils"
import { getEtcGmtTimezone, getGmtOffset } from "@/utils/date"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import { SubmitErrorHandler } from "react-hook-form"
import { useTranslation } from "react-i18next"
import useFormater from "../useFormater"
import useNotification from "../useNotification"
import useUiPageSession from "../useUiPageSession"
import useCampaignCredit from "./useCampaignCredit"
import useCampaignForm from "./useCampaignForm"
import useCampaignMemo from "./useCampaignMemo"

const useCampaign = () => {
    useUiPageSession({
        title: "CAMPAIGN.TITLE",
        page: "campaign",
    })
    const { t } = useTranslation()
    const { update, contacts, locations, noCredit } = CampaignStore()
    const { update: updateReviewsClient, listReviewsClient, pagination: paginationReviews } = CampaignReviewsStore()
    const { formValue, handleSubmit, reset, control, formState, register, setValue, trigger } = useCampaignForm()
    const {
        atValues,
        currentLocation,
        hasCredit,
        hasNewUrl,
        infos,
        isDisabled,
        isThereNewContact,
        phoneIsValid,
        remainingCredits,
        step1isValid,
        step2isValid,
        typeLabel,
    } = useCampaignMemo({ formValue })

    const { sendRequestCredit, fetchCredit, askCreditModal } = useCampaignCredit()

    const { parseVariables } = useFormater()
    const { notif } = useNotification()
    const { updatePreviousItem } = UiPageSessionStore()
    const { preferredLanguage } = UiStore()
    const [hasModel, setHasModel] = useState<boolean>(undefined)
    const [listReviews, setListReviews] = useState([])

    const steps = [t("CAMPAIGN.RECIPIENTS"), t("CAMPAIGN.MESSAGE"), t("CAMPAIGN.PREVIEW")]

    const handleMessage = useCallback(
        (value: { html: string; delta: any; rawText: string; editorText: string }) => {
            setValue("message", value.html)
        },
        [setValue]
    )

    const handleNext = useCallback(() => {
        setValue("step", formValue?.step + 1)
    }, [setValue, formValue?.step])

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }

    const handleBack = useCallback(() => {
        updatePreviousItem({
            campaign: formValue,
            // message,
        })
        setValue("step", formValue?.step - 1)
    }, [formValue, setValue, updatePreviousItem])

    const handleCancel = () => {
        handleBack()
    }

    const handleGetAllReviews = useCallback(
        async (page = 0) => {
            if (locations && locations?.length > 0) {
                updateReviewsClient({ isLoading: true })
                const locationIds = locations?.map((l) => l.id)
                const res: IListReviewsClient = await CampaignApi.getAllReviews({
                    locations_id: locationIds,
                    _page: page + 1,
                    _limit: paginationReviews.per_page,
                })
                const locationsById = groupByKey(locations, "id")
                if (res?.data) {
                    const timezoneUser = getEtcGmtTimezone(getGmtOffset())
                    const data = res.data.map((item) => {
                        const formattedDate = dayjs.utc(item?.created).tz(timezoneUser).format("YYYY-MM-DDTHH:mm")
                        const location = locationsById?.[item.location_id]?.[0]
                            ? locationsById[item.location_id][0]
                            : null
                        return {
                            ...item,
                            created: formattedDate,
                            location: location,
                        }
                    })
                    updateReviewsClient({
                        listReviewsClient: data,
                        pagination: { ...res.metadata, page: page },
                        isLoading: false,
                    })
                }
            }
        },
        [locations, paginationReviews.per_page]
    )

    const send = useCallback(async () => {
        update({
            isLoading: true,
            dataSent: false,
        })
        const finalText = contacts.length
            ? parseVariables(formValue?.message, contacts[0], currentLocation?.name)
            : parseVariables(
                  formValue?.message,
                  {
                      firstname: formValue?.firstname,
                      lastname: formValue?.lastname,
                      info: formValue?.type === "sms" ? formValue?.phone : formValue?.email,
                  },
                  currentLocation?.name
              )

        const messages = contacts.length
            ? contacts.map((contact) => {
                  return {
                      to: contact[typeLabel],
                      body: parseVariables(
                          formValue?.type === "sms"
                              ? convertHtml(removeDuplicateUrl(formValue?.message))
                              : formValue?.message,
                          contact,
                          currentLocation?.name
                      ),
                  }
              })
            : [
                  {
                      to: formValue?.type === "sms" ? formValue?.phone : formValue?.email,
                      body: formValue?.type === "sms" ? convertHtml(removeDuplicateUrl(finalText)) : finalText,
                  },
              ]

        const body: SendCampaignProps = {
            type: formValue?.type,
            companyId: currentLocation?.companyId,
            location: {
                id: currentLocation?.id,
                name: currentLocation?.name,
                logo: currentLocation?.logo,
                zip: currentLocation?.zip,
                province_code: currentLocation?.region,
                country: currentLocation?.country,
                city: currentLocation?.city,
                business_type: currentLocation?.serviceArea?.businessType ?? "",
            },
            feedback: formValue?.feedback,
            content: messages,
        }

        if (currentLocation?.locationState?.newReviewUri) {
            body["longLink"] = currentLocation?.locationState?.newReviewUri
        }

        body["lang"] = formValue?.lang?.toLowerCase() || "en"

        if (formValue?.type === "sms") {
            body["smsSender"] = CookiesService.get("smsSender")
        } else {
            body["subject"] = formValue?.subject
            body["from"] = CookiesService.get("emailSpotconnect")
            body["location"]["address"] = currentLocation?.address
        }
        try {
            const response = await CampaignApi.send({ body })
            if (response?.data === "success") {
                update({
                    isLoading: false,
                    dataSent: true,
                })
            } else {
                update({
                    isLoading: false,
                    dataSent: false,
                })
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        } catch (error) {
            update({
                isLoading: false,
                dataSent: false,
            })
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }, [
        update,
        contacts,
        parseVariables,
        formValue?.message,
        formValue?.firstname,
        formValue?.lastname,
        formValue?.type,
        formValue?.phone,
        formValue?.email,
        formValue?.feedback,
        formValue?.lang,
        formValue?.subject,
        currentLocation?.name,
        currentLocation?.companyId,
        currentLocation?.id,
        currentLocation?.logo,
        currentLocation?.zip,
        currentLocation?.region,
        currentLocation?.country,
        currentLocation?.city,
        currentLocation?.locationState?.newReviewUri,
        currentLocation?.address,
        typeLabel,
        notif,
        t,
    ])

    const onSubmit = useCallback(() => {
        switch (formValue?.step) {
            case 0:
                if (remainingCredits < contacts.length) {
                    update({
                        noCredit: true,
                    })
                } else {
                    update({
                        businessSelect: { business: formValue.business, lang: preferredLanguage },
                    })
                    handleNext()
                }
                break
            case 1:
                if (
                    (formValue?.type == "sms" && !formValue.message.includes('data-id="url"')) ||
                    (formValue?.type == "email" && !formValue.message.includes('data-id="url"') && !formValue?.feedback)
                ) {
                    const HtmlLink =
                        '<p><span class="mention" data-index="2" data-denotation-char="@" data-id="url" data-value="url"><span contenteditable="false">@url</span></span> </p>'
                    setValue("message", formValue.message + HtmlLink)
                }
                handleNext()
                break
            default:
                send()
                break
        }
    }, [
        formValue?.step,
        formValue?.type,
        formValue.message,
        formValue?.feedback,
        formValue.business,
        remainingCredits,
        contacts?.length,
        preferredLanguage,
        handleNext,
        send,
        update,
        setValue,
    ])

    const sendRequest = useCallback(() => {
        sendRequestCredit({
            companyId: currentLocation?.companyId,
            type: formValue?.type,
        })
    }, [currentLocation?.companyId, formValue?.type, sendRequestCredit])

    const closeSuccessMessage = useCallback(() => {
        update({
            activeStep: 0,
            dataSent: false,
            // message: "",
            credits: [],
            contacts: [],
        })
        reset()
        handleGetAllReviews()
        setValue("lang", preferredLanguage !== "en" ? "fr-FR" : "en")
    }, [handleGetAllReviews, preferredLanguage])

    const fetchReviews = useCallback(async () => {
        if (formValue?.business) {
            const response = await EditModelService.fetchReviews(formValue.business)
            if (response) {
                setHasModel(response.has_model)
                setListReviews(response.data)
            }
        }
    }, [formValue?.business])

    const onFormValueTypeChange = useCallback(() => {
        if (formValue?.type) {
            update({
                contacts: [],
                file: null,
                pagination: {
                    total_count: 0,
                    page_number: 0,
                    page_size: 10,
                    rows: [],
                },
            })
            setValue("confirmation", false)
        }
    }, [formValue?.type, setValue, update])

    const onSelectedBusinessChanged = useCallback(() => {
        if (formValue?.business && locations?.length > 0 && currentLocation?.companyId !== undefined) {
            fetchCredit(currentLocation?.companyId)
            setValue("phone", "")
            // setValue("country", currentLocation?.country || "CA")
            // setValue("type", "sms")
            update({
                defaultFlag: currentLocation?.country || "CA",
                contacts: [],
                pagination: {
                    total_count: 0,
                    page_number: 0,
                    page_size: 10,
                    rows: [],
                },
            })
        } else {
            update({
                credits: [],
                creditFetched: false,
            })
        }
    }, [
        currentLocation?.companyId,
        currentLocation?.country,
        formValue?.business,
        locations?.length,
        fetchCredit,
        setValue,
        update,
    ])

    const onNavigatorLanguageChanged = useCallback(() => {
        if (preferredLanguage) {
            setValue("lang", preferredLanguage !== "en" ? "fr-FR" : "en")
        }
    }, [preferredLanguage, setValue])

    const cleanUp = useCallback(() => {
        reset()
        update({
            contacts: [],
            file: null,
            pagination: {
                total_count: 0,
                page_number: 0,
                page_size: 10,
                rows: [],
            },
            dataSent: false,
        })
        setValue("confirmation", false)
    }, [reset, setValue, update])

    // useEffect(() => {
    //     onFileChange()
    // }, [onFileChange])

    useEffect(() => {
        onSelectedBusinessChanged()
    }, [onSelectedBusinessChanged])

    useEffect(() => {
        onFormValueTypeChange()
    }, [onFormValueTypeChange])

    useEffect(() => {
        handleGetAllReviews()
    }, [handleGetAllReviews])

    useEffect(() => {
        return () => {
            cleanUp()
        }
    }, [cleanUp])

    useEffect(() => {
        onNavigatorLanguageChanged()
    }, [onNavigatorLanguageChanged])

    useEffect(() => {
        if (!noCredit) {
            askCreditModal({ open: false })
        }
    }, [askCreditModal, noCredit, update])

    useEffect(() => {
        if (step1isValid == undefined) {
            fetchReviews()
        }
    }, [fetchReviews, step1isValid])

    return {
        control,
        formState,
        atValues,
        step2isValid,
        steps,
        phoneIsValid,
        hasNewUrl,
        currentLocation,
        infos,
        isDisabled,
        isThereNewContact,
        noCredit,
        hasCredit,
        listReviews,
        hasModel,
        formValue,
        handleSubmit,
        setValue,
        register,
        handleMessage,
        onErrors,
        closeSuccessMessage,
        handleCancel,
        onSubmit,
        fetchReviews,
        sendRequest,
        trigger,
        listReviewsClient,
        handleGetAllReviews,
    }
}

export default useCampaign
