import SocialPopupStore from "@/store/social-popup"
import useQueryParams from "../useQueryParams"

const useQuerySocial = () => {
    const { setQueryParams, removeAllQueryParams } = useQueryParams()
    const { flush: flushSocialPopupStore } = SocialPopupStore()
    const createPost = (scheduled?: boolean, date?: string) => {
        const keys = {
            "create-post": "1",
        }
        if (scheduled) {
            keys["scheduled"] = date
        }
        setQueryParams(keys)
    }

    const editPost = (id = "") => {
        setQueryParams({ "edit-post": id, show: "single_post" })
    }

    const deletePost = (id = "") => {
        setQueryParams({ "delete-post": id })
    }

    const openPosts = (date) => {
        setQueryParams({ posts: date })
    }

    const closeModal = () => {
        flushSocialPopupStore()
        removeAllQueryParams()
    }

    return {
        createPost,
        editPost,
        deletePost,
        closeModal,
        openPosts,
    }
}

export default useQuerySocial
