import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import FirstStepCampaign from "@/components/campaign/first-step"
import FormAction from "@/components/campaign/form-action"
import NoCreditModal from "@/components/campaign/no-credit-modal"
import SecondStepCampaign from "@/components/campaign/second-step"
import SuccessPage from "@/components/campaign/send-success"
import useStyles from "@/components/campaign/styles"
import ThirdStepCampaign from "@/components/campaign/third-step"
import { SkeletonCampaign } from "@/components/skeleton/campaign"
import useCampaign from "@/hooks/campaign/useCampaign"
import useCampaignCredit from "@/hooks/campaign/useCampaignCredit"
import CampaignStore from "@/store/campaign"
import { Container } from "@mui/material"
import Box from "@mui/material/Box"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Stepper from "@mui/material/Stepper"
import { useTranslation } from "react-i18next"

const CampaignPage = () => {
    const { t } = useTranslation()
    const {
        control,
        formState,
        atValues,
        step2isValid,
        steps,
        phoneIsValid,
        hasNewUrl,
        currentLocation,
        infos,
        isDisabled,
        isThereNewContact,
        noCredit,
        hasCredit,
        listReviews,
        hasModel,
        formValue,
        handleSubmit,
        setValue,
        register,
        handleMessage,
        onErrors,
        closeSuccessMessage,
        handleCancel,
        onSubmit,
        fetchReviews,
        sendRequest,
        handleGetAllReviews,
    } = useCampaign()

    const { isLoading, dataSent, creditAsked, loadingBusiness } = CampaignStore()
    const { closeCreditModal } = useCampaignCredit()

    const { classes } = useStyles()

    return (
        <Container className={classes.container}>
            <TitleWrapper pageIcon="announcement" title={t("CAMPAIGN.NEW_OPINION")} />
            {loadingBusiness && <SkeletonCampaign />}
            {!dataSent && !loadingBusiness && (
                <>
                    <Stepper sx={{ maxWidth: "470px" }} activeStep={formValue?.step}>
                        {steps.map((label) => {
                            const stepProps: { completed?: boolean } = {}
                            const labelProps: {
                                optional?: React.ReactNode
                            } = {}

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                    <Box className={classes.boxContainer}>
                        {formValue?.step === 0 && ( //Refactor to Store
                            <FirstStepCampaign
                                errors={formState?.errors}
                                control={control}
                                formValue={{ ...formValue, hasCredit, location: currentLocation }}
                                setValue={setValue}
                                defaultType={formValue?.type === "sms" ? 0 : 1}
                                onRequestCredit={sendRequest}
                                handleGetAllReviews={handleGetAllReviews}
                            />
                        )}
                        {formValue?.step === 1 && (
                            <SecondStepCampaign
                                type={formValue?.type}
                                listReviews={listReviews}
                                hasModel={hasModel}
                                onChange={handleMessage}
                                onCloseReviews={fetchReviews}
                                register={register}
                                setValue={setValue}
                                atValues={atValues}
                                data={{ ...infos, ...currentLocation }}
                                defaultValue={formValue?.message}
                                feedback={formValue?.feedback}
                                hasNewUrl={hasNewUrl}
                                formValue={formValue}
                            />
                        )}
                        {formValue?.step === 2 && (
                            <ThirdStepCampaign
                                type={formValue?.type}
                                html={formValue?.message}
                                businessName={currentLocation.name}
                                feedback={formValue?.feedback}
                                data={{ ...infos, ...currentLocation }}
                            />
                        )}
                        <FormAction
                            handleCancel={() => handleCancel()}
                            isDisabled={isLoading || isThereNewContact || isDisabled || !step2isValid || !phoneIsValid}
                            isLoading={isLoading}
                            isCampaign={true}
                            step={formValue?.step}
                            onSubmit={handleSubmit(onSubmit, onErrors)}
                        />
                    </Box>
                </>
            )}
            {dataSent && <SuccessPage open={dataSent} onClose={closeSuccessMessage} />}
            <NoCreditModal
                open={noCredit}
                onClose={closeCreditModal}
                dataSent={creditAsked}
                onRequestCredit={sendRequest}
                zeroCredit={!hasCredit}
            />
        </Container>
    )
}

export default CampaignPage
