import useLocationStore from "@/store/location"
import SocialStore from "@/store/social"
import { useMemo, useState } from "react"
import TableSocialCheck from "../../table-social-check"

const ConnexionPostDetail = () => {
    const { selectedBusinesses } = SocialStore()
    const { businesses } = useLocationStore()
    const [provider, setProvider] = useState([])

    const rows = useMemo(() => {
        const businessMap = businesses.reduce((acc, business) => {
            acc[business.value] = business
            return acc
        }, {})

        const _provider = []

        const getProvider = (meta) => {
            if (meta?.facebook_id) return "facebook"
            if (meta?.instagram_id) return "instagram"
            if (meta?.gmb_id) return "google"
            return null
        }

        const rows = selectedBusinesses.map((selectedBusiness) => {
            const business = businessMap[selectedBusiness] || {}
            const provider = getProvider(business.meta)
            if (!_provider.includes(provider)) {
                _provider.push(provider)
            }
            return {
                name: business.label,
                address: business.shortAddress,
                facebook: business.meta?.facebook_id,
                instagram: business.meta?.instagram_id,
                google: business.meta?.gmb_id,
            }
        })

        setProvider(_provider)

        return rows
    }, [businesses, selectedBusinesses])

    return <TableSocialCheck rows={rows} providers={provider} />
}

export default ConnexionPostDetail
