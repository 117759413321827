import { LocationsApi } from "@/api"
import CampaignStore from "@/store/campaign"
import useLocationStore from "@/store/location"
import UserSessionStore from "@/store/user-session"
import { useCallback } from "react"

const useBusinessHooks = () => {
    const { profile } = UserSessionStore()
    const { update, businesses } = useLocationStore()
    const { update: updateCampaign } = CampaignStore()
    const fetchBusinessList = useCallback(
        async (fields = ["uid", "name", "address", "city", "meta", "locationState"]) => {
            if (!profile?.uid) {
                return
            }
            if (businesses) {
                return businesses
            }
            const entities = await LocationsApi.fetchLocationsWithFields({
                user_uid: profile?.uid,
                fields,
            })
            const business = entities?.data ?? []
            const mappedEntities = [...business]?.map((location) => {
                return {
                    label: location?.name,
                    value: location?.id,
                    shortAddress: `${
                        location?.address !== undefined && !!location?.address ? location?.address + "," : ""
                    } ${location?.city !== undefined && !!location?.city ? location?.city : ""}`,
                    meta: location?.meta,
                    showPostAlert: location?.locationState?.showPostAlert ?? true,
                }
            })
            update({ businesses: mappedEntities })
            updateCampaign({ locations: business })
            return mappedEntities
        },
        [profile?.uid, update]
    )

    return {
        fetchBusinessList,
    }
}

export default useBusinessHooks
