import { FetchKpiInput } from "@/api/statistics"
import { StatisticReputationService } from "@/services"

import KpiReputationStore from "@/store/kpi-reputation"
import useLocationStore from "@/store/location"
import UiStore from "@/store/ui"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useMemo } from "react"
import useUiPageSession from "../useUiPageSession"

const useLogic = () => {
    const {
        graph,
        kpi,
        payloadFilter,
        update,
        selectedBusinesses,
        selectedPeriod,
        loading,
        updatePayload,
        updatePeriod,
    } = KpiReputationStore()
    const { profile } = UserSessionStore()
    const { businesses } = useLocationStore()
    const { preferredLanguage } = UiStore()
    useUiPageSession({
        title: "REPUTATION.TITLE_PERFORMANCE",
        page: "local_presence",
    })

    const handleSelectionChange = useCallback((items) => {
        const filteredLocationIds = items?.map((item) => item.value) || []
        if (filteredLocationIds?.length > 0) {
            update({
                selectedBusinesses: items,
            })
            updatePayload({ locationsId: filteredLocationIds })
        } else {
            updatePayload({ locationsId: [] })
            update({ selectedBusinesses: [] })
        }
    }, [])

    const handleSelectionPeriodChange = useCallback((item) => {
        let type = "year"
        if (item.value === 1) {
            type = "month"
        } else if (item.value === 0) {
            type = "all"
        }
        updatePayload({ type: type })
        updatePeriod(item)
    }, [])

    const defaultIds = useMemo(async () => {
        if (businesses) {
            return businesses?.map((data) => String(data.value)) || []
        }
        return []
    }, [businesses])

    const fetchKpi = useCallback(
        async (filterParams: Partial<FetchKpiInput>) => {
            update({ loading: true })

            const ids = payloadFilter.locationsId.length > 0 ? payloadFilter.locationsId : await defaultIds
            if (ids) {
                const locationsId = filterParams?.locationsId?.length > 0 ? filterParams.locationsId : ids
                const response = await StatisticReputationService.fetchKpiReputation({
                    ...payloadFilter,
                    ...filterParams,
                    locationsId,
                })
                update({ graph: response?.graph, kpi: response?.kpi })
            }

            update({ loading: false })
        },
        [payloadFilter, defaultIds]
    )

    const testKpi = () => {
        if (
            kpi?.period?.review_rating_average == 0 &&
            kpi?.period?.review_count == 0 &&
            kpi?.period?.review_treated_rate == 0
        ) {
            return false
        }
        return true
    }

    const graphs = useMemo(() => {
        if (graph)
            return {
                average_grade: StatisticReputationService.resolveGraphsAverageGrade(
                    graph,
                    payloadFilter.type,
                    preferredLanguage
                ),
                review_treated: StatisticReputationService.resolveGraphsAReviewTreated(
                    graph,
                    payloadFilter.type,
                    preferredLanguage
                ),
                review_count: StatisticReputationService.resolveGraphsReviewCount(
                    graph,
                    payloadFilter.type,
                    preferredLanguage
                ),
                feedback: StatisticReputationService.resolveGraphsFeedback(
                    graph,
                    payloadFilter.type,
                    preferredLanguage
                ),
            }
        return {
            average_grade: {},
            review_treated: {},
            review_count: {},
            feedback: {},
        }
    }, [graph])

    useEffect(() => {
        fetchKpi({})
    }, [fetchKpi])

    return {
        handleSelectionChange,
        handleSelectionPeriodChange,
        kpi,
        profile,
        graph,
        payloadFilter,
        testKpi,
        graphs,
        loading,
        selectedBusinesses,
        selectedPeriod,
    }
}

export default useLogic
