import { CompanyApi, LocationsApi, UserApi } from "@/api"
import { MAX_INPUT } from "@/data/limiter"
import useNotification from "@/hooks/useNotification"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import UsersStore from "@/store/users"
import { ICompaniesResponse } from "@/types/companies"
import { OptionType } from "@/types/option"
import { FormValues } from "@/types/user"
import { decode64AndParse } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

const useLogic = ({ isUpdate, callApi, onCancelForm }) => {
    const [mailError, setMailError] = useState("")
    // const [adminDefaultValue, setAdminDefaultValue] = useState()
    const { updateState: setUiPageSession, selectedItem, previousItem, updatePreviousItem } = UiPageSessionStore()
    const { notif } = useNotification()
    const [loading, setLoading] = useState<boolean>(false)
    const { preferredLanguage } = UiStore()
    const { profile, role: roleBase64 } = UserSessionStore()
    const { update: updateUserStore, companies, locations, companiesFetched, locationsFetched } = UsersStore()
    const { t } = useTranslation()
    const MESSAGE_TEXT = useMemo(
        () => ({
            SAME_EMAIL: t("USERS.SAME_EMAIL"),
            INVALID_REQUEST: t("SYSTEM_ERROR.INVALID_REQUEST"),
            USER_CREATE_SUCCESS: t("USERS.CREATE.SUCCESS"),
            USER_UPDATE_SUCCESS: t("USERS.UPDATE.SUCCESS"),
            FIELD_REQUIRED: t("USERS.FIELD_REQUIRED"),
            CANCEL: t("USERS.CANCEL"),
            BACK: t("USERS.BACK_TOOLTIP"),
            USER_CREATE_SAVE: t("USERS.CREATE.SAVE"),
            USER_UPDATE: t("USERS.SAVE"),
            USER_EMAIL: t("USERS.E-MAIL"),
            USER_LAST_NAME: t("USERS.LAST_NAME"),
            USER_FIRST_NAME: t("USERS.FIRST_NAME"),
            USER_CREATE_TITLE: t("USERS.CREATE.TITLE"),
            INVALID_FORMAT_EMAIL: t("USERS.INVALID_FORMAT_EMAIL"),
            MAIN_INFO: t("USERS.MAIN_INFO"),
            ROLE_TITLE: t("USERS.ROLE_TITLE_CREATE"),
            ROLE: t("USERS.ROLE"),
            COMPANY_ROLE: t("USERS.COMPANY_ROLE"),
            BUSINESS_ROLE: t("USERS.BUSINESS_ROLE"),
            ADMIN_COMPANY_ROLE: t("USERS.ADMIN_COMPANY_ROLE"),
            ADMIN_BUSINESS_ROLE: t("USERS.ADMIN_BUSINESS_ROLE"),
        }),
        [t]
    )

    const roles = useMemo<OptionType[]>(() => {
        if (profile?.uid && roleBase64) {
            const roleList: string = decode64AndParse(roleBase64)
            const hasCompanyRole =
                roleList?.includes("ROLE_ADMIN_COMPANY") &&
                !roleList?.includes("ROLE_ADMIN_LOCATION") &&
                !roleList?.includes("ROLE_ADMIN_ESTABLISHMENT")
            if (hasCompanyRole) {
                const twoRolesOptions: any = [
                    {
                        label: MESSAGE_TEXT.ADMIN_COMPANY_ROLE,
                        value: "admin_company",
                    },
                    {
                        label: MESSAGE_TEXT.ADMIN_BUSINESS_ROLE,
                        value: "admin_location",
                    },
                ]
                return twoRolesOptions
            } else if (
                roleList &&
                (roleList.includes("ROLE_ADMIN_LOCATION") || roleList.includes("ROLE_ADMIN_ESTABLISHMENT"))
            ) {
                const oneRolesOptions: any = [
                    {
                        label: MESSAGE_TEXT.ADMIN_BUSINESS_ROLE,
                        value: "admin_location",
                    },
                ]

                return oneRolesOptions
            }
        }
        return []
    }, [MESSAGE_TEXT.ADMIN_BUSINESS_ROLE, MESSAGE_TEXT.ADMIN_COMPANY_ROLE, profile?.uid, roleBase64])

    const schema = useMemo(() => {
        return z.object({
            firstname: z.string().trim().max(MAX_INPUT.TEXT).min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            lastname: z.string().trim().max(MAX_INPUT.TEXT).min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            email: z
                .string()
                .trim()
                .min(1, MESSAGE_TEXT.FIELD_REQUIRED)
                .max(MAX_INPUT.TEXT)
                .email({ message: t("USERS.INVALID_FORMAT_EMAIL") }),
            role: z.string().min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            roleByAdminType: z.union([
                z.array(z.string()).nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
                z.string().min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            ]),
        })
    }, [MESSAGE_TEXT.FIELD_REQUIRED, t])

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        control,
        trigger,
        setValue,
        setError: setFormError,
        getValues,
        reset,
    } = useForm<FormValues>({
        defaultValues: {
            email: "",
            firstname: "",
            lastname: "",
            role: "",
            roleByAdminType: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
        shouldUnregister: true,
    })

    const formValue = useWatch({ control })

    const listItemsAutocomplete = useMemo(() => {
        if (formValue?.role === "admin_company") {
            return companies
        }
        return locations
    }, [locations, companies, formValue?.role])

    const isAutoEdit = useMemo(
        () => profile?.uid === selectedItem?.uid && isUpdate,
        [profile?.uid, selectedItem, isUpdate]
    )

    const setValues = useCallback(
        (data) => {
            if (data) {
                setValue("firstname", data.firstname)
                setValue("lastname", data.lastname)
                setValue("email", data.email)
                setValue("role", data.role)
                setValue("roleByAdminType", data.roleByAdminType)
                trigger(["role", "roleByAdminType"])
            }
        },
        [setValue, trigger]
    )

    const refreshListLocations = useCallback(async () => {
        try {
            const response = await LocationsApi.fetchLocationsWithFields({
                user_uid: profile.uid,
                fields: [
                    "uid",
                    "meta",
                    "id",
                    "logo",
                    "address",
                    "zip",
                    "region",
                    "country",
                    "locationState",
                    "newReviewUri",
                    "companyId",
                    "name",
                    "gmbAccessId",
                    "address",
                    "city",
                    "serviceArea",
                ],
            })
            if (response?.data) {
                const locations = response.data.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                        shortAddress: `${item.address}, ${item.city}`,
                    }
                })
                updateUserStore({ locations, locationsFetched: true })
                if (locations.length === 1) {
                    setValue("roleByAdminType", [locations[0].value])
                    trigger("roleByAdminType")
                }
            }
        } catch (error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
        return null
    }, [notif, profile.uid, setValue, t, trigger, updateUserStore])

    const refreshListCompany = useCallback(async () => {
        try {
            const user_uid: any = profile.uid
            let response: ICompaniesResponse = null
            response = await CompanyApi.fetchListsCompanies({ user_uid, fields: "name,uid" })
            if (response?.data) {
                const companies = response.data.map((item) => {
                    return {
                        label: item.name,
                        value: item.uid,
                    }
                })
                updateUserStore({ companies, companiesFetched: true })
                if (companies.length === 1) {
                    setValue("roleByAdminType", [companies[0].value])
                    trigger("roleByAdminType")
                }
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        } catch (error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
        return null
    }, [notif, profile.uid, setValue, t, trigger, updateUserStore])

    const resetForm = useCallback(() => {
        reset({
            email: "",
            firstname: "",
            lastname: "",
            role: "",
            roleByAdminType: "",
        })
        setUiPageSession({ previousItem: null, selectedItem: null })
    }, [reset, setUiPageSession])

    const createOrUpdateUser = useCallback(
        async (data: FormValues) => {
            let companyTab = []
            let establishmentTab = []
            if (data.role === "admin_company") {
                companyTab = Array.isArray(data.roleByAdminType) ? data.roleByAdminType : [data.roleByAdminType]
            } else {
                establishmentTab = Array.isArray(data.roleByAdminType) ? data.roleByAdminType : [data.roleByAdminType]
            }
            const userObject: any = {
                uid: selectedItem?.uid,
                user_uid: profile?.uid,
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                lang: preferredLanguage,
                roles: [data.role],
            }
            if (establishmentTab.length > 0) {
                userObject["locations"] = establishmentTab
            }
            if (companyTab.length > 0) {
                userObject["companies"] = companyTab
            }

            const response = await callApi(userObject)

            if (response?.error) {
                if (response.error === "USER_ALREADY_EXIST") {
                    setFormError("email", { message: "" }, { shouldFocus: true })
                    setMailError(MESSAGE_TEXT.SAME_EMAIL)
                } else {
                    notif({ message: MESSAGE_TEXT.INVALID_REQUEST, type: "ERROR" })
                }
            } else {
                await updateUserStore({ refreshUsers: true })
                setUiPageSession({ showPage: "list", previousItem: null })
                notif({
                    message: isUpdate ? MESSAGE_TEXT.USER_UPDATE_SUCCESS : MESSAGE_TEXT.USER_CREATE_SUCCESS,
                    type: "SUCCESS",
                })
            }
        },
        [
            selectedItem?.uid,
            profile?.uid,
            preferredLanguage,
            callApi,
            setFormError,
            MESSAGE_TEXT.SAME_EMAIL,
            MESSAGE_TEXT.INVALID_REQUEST,
            MESSAGE_TEXT.USER_UPDATE_SUCCESS,
            MESSAGE_TEXT.USER_CREATE_SUCCESS,
            notif,
            updateUserStore,
            setUiPageSession,
            isUpdate,
        ]
    )

    const asyncLoad = useCallback(async () => {
        const selected_user_uid = selectedItem?.uid
        const restorePreviousItemFromEdit =
            previousItem && previousItem?.fromEdit && previousItem?.uid === selected_user_uid

        if (!isUpdate) {
            resetForm()
        } else if (restorePreviousItemFromEdit) {
            setValues(previousItem)
        } else if (selected_user_uid && isUpdate) {
            try {
                const response = await UserApi.fetchUserInfo({
                    id: selected_user_uid,
                    user_uid: profile.uid,
                })
                const data = { ...response }
                data.role = response?.roles?.includes("ROLE_ADMIN_COMPANY") ? "admin_company" : "admin_location"
                if (data.role === "admin_company") {
                    data.roleByAdminType = data.companies
                } else {
                    data.roleByAdminType = data.locations
                }
                setValues(data)
                updatePreviousItem({ ...data, fromEdit: true })
            } catch (error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        } else {
            resetForm()
        }
    }, [resetForm, selectedItem?.uid, previousItem, isUpdate, setValues, profile.uid, updatePreviousItem, notif, t])

    useEffect(() => {
        if (!companiesFetched) {
            refreshListCompany()
        }
    }, [companiesFetched, refreshListCompany])

    useEffect(() => {
        if (!locationsFetched) {
            refreshListLocations()
        }
    }, [locationsFetched, refreshListLocations])

    useEffect(() => {
        if (companiesFetched && locationsFetched) {
            asyncLoad()
        }
    }, [asyncLoad, companiesFetched, locationsFetched])

    const onSubmit: SubmitHandler<any> = useCallback(
        async (data: FormValues) => {
            setMailError("")
            const emailValidationSchema = z.object({
                email: z.string().email(),
            })
            try {
                emailValidationSchema.parse({
                    email: formValue?.email,
                })
                setLoading(true)
                await createOrUpdateUser(data)
            } catch (error) {
                if (error instanceof z.ZodError) {
                    setMailError(MESSAGE_TEXT.INVALID_FORMAT_EMAIL)
                }
            }
            setLoading(false)
        },
        [MESSAGE_TEXT.INVALID_FORMAT_EMAIL, createOrUpdateUser, formValue?.email]
    )

    const handeCancelButton = useCallback(() => {
        resetForm()
        onCancelForm()
    }, [onCancelForm, resetForm])

    useEffect(() => {
        return () => {
            if (updatePreviousItem) {
                updatePreviousItem({
                    ...getValues(),
                    uid: selectedItem?.uid,
                    fromEdit: isUpdate,
                })
            }
        }
    }, [getValues, isUpdate, selectedItem?.uid, updatePreviousItem])

    useEffect(() => {
        setMailError("")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValue?.email])

    useEffect(() => {
        if (roles.length === 1 && !isUpdate) {
            setValue("role", roles[0].value)
        }
        if (formValue?.role) {
            if (!isUpdate) {
                if (formValue?.role === "admin_company" && companies?.length === 1) {
                    setValue("roleByAdminType", [companies[0].value])
                    trigger("roleByAdminType")
                } else if (formValue?.role === "admin_location" && locations?.length === 1) {
                    setValue("roleByAdminType", [locations[0].value])
                    trigger("roleByAdminType")
                }
            }
            trigger("role")
        }
    }, [companies, formValue?.role, isUpdate, locations, roles, setValue, trigger])

    return {
        handleSubmit,
        onSubmit,
        register,
        createOrUpdateUser,
        control,
        MESSAGE_TEXT,
        mailError,
        setMailError,
        roles,
        isAutoEdit,
        formValue,
        listItemsAutocomplete,
        setValue,
        trigger,
        t,
        resetForm,
        loading,
        profile,
        errors,
        isValid,
        handeCancelButton,
    }
}

export default useLogic
