import ReactDOM from "react-dom/client"
import "swiper/css"
import App from "./App"
import env from "./config/env"
import "./i18n"
import i18n from "./i18n"
import "./index.css"
import { NotificationService, SentryService, ServiceWorker } from "./services"

const root = ReactDOM.createRoot(document.getElementById("root"))

SentryService.init()

root.render(<App />)

if (env.ENV === "local") {
    ServiceWorker.unregister()
} else {
    // i18n.reloadResources()
    ServiceWorker.register({
        onUpdate: () => {
            NotificationService.setState({
                type: "UPDATE",
                message: "",
                show: true,
            })
        },
    })
}
