import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { withSxProp } from "@/utils"
import { Box, Dialog, IconButton, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { modalPost } from "../../style"
import { ContentConfirmDelete } from "./content"

export const ConfirmDelete = ({ open, handleClose, handleConfirm, isLoading }) => {
    const { t } = useTranslation()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            disableRestoreFocus
            scroll="body"
            PaperProps={{ elevation: 6, sx: withSxProp(modalPost("460px", "alert")) }}
        >
            <Stack className="bloc-modal">
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" variant="h2">
                        {t("SOCIALS.ALERT_DELETE.TITLE")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={handleClose}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <ContentConfirmDelete handleClose={handleClose} handleConfirm={handleConfirm} isLoading={isLoading} />
            </Stack>
        </Dialog>
    )
}
