import { SocialsApi } from "@/api"
import SocialStore from "@/store/social"
import UserSessionStore from "@/store/user-session"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"
import { getEtcGmtTimezone, getGmtOffset } from "@/utils/date"
import dayjs from "dayjs"

const usePageLogic = () => {
    const { profile } = UserSessionStore()
    const { update: updateSocial, businesses, currentMonth } = SocialStore()
    const { notif } = useNotification()
    const { t } = useTranslation()

    const groupPostsByDay = (posts) => {
        return posts.reduce((acc, post) => {
            // Extract the date part from the publishAt field (YYYY-MM-DD)
            if (post?.publishAt) {
                const timezoneUser = getEtcGmtTimezone(getGmtOffset())
                const publishAt = dayjs.utc(post?.publishAt).tz(timezoneUser).format("YYYY-MM-DDTHH:mm:ss")

                const [year, month, day] = publishAt.split("T")[0].split("-")

                const formattedDate = `${year}-${parseInt(month)}-${parseInt(day)}`
                if (!acc[formattedDate]) {
                    acc[formattedDate] = []
                }

                acc[formattedDate].push(post)

                // Sort the posts for the current date
                acc[formattedDate].sort((a, b) => {
                    const statusOrder = { LIVE_W_ERROR: 0, DRAFT: 1, SCHEDULED: 2, IN_PROGRESS: 2, LIVE: 2 }

                    // Compare by status
                    const statusComparison = statusOrder[a.status] - statusOrder[b.status]
                    if (statusComparison !== 0) {
                        return statusComparison
                    }

                    // Compare by publishAt
                    return dayjs(a.publishAt).diff(dayjs(b.publishAt))
                })
            }

            return acc
        }, {})
    }

    const fetchPosts = useCallback(async () => {
        const dateFormatted = currentMonth.split("-")
        const year = parseInt(dateFormatted?.[0])
        const month = parseInt(dateFormatted?.[1])

        if (profile?.uid && businesses) {
            const date = new Date()
            const currentMonth = month ?? date.getMonth() + 1
            const currentYear = year ?? date.getFullYear()
            const resp = await SocialsApi.fetchPosts({
                month: currentMonth,
                year: currentYear,
                locationIds: businesses,
                fields: "",
            })
            if (!resp?.error) {
                updateSocial({
                    posts: groupPostsByDay(resp),
                    draftPosts: resp.filter((data) => data.status === "DRAFT"),
                })
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        }
    }, [profile, businesses, currentMonth])

    return {
        fetchPosts,
        groupPostsByDay,
    }
}

export default usePageLogic
