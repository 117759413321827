import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EstablishmentHeader from "@/components/business/Header"
import AdditionalInfo from "@/components/business/additional-info"
import OverviewAttribute from "@/components/business/overview/attribute"
import OverviewBusinessCategory from "@/components/business/overview/category"
import ConnectInstagramModal from "@/components/business/overview/connect-instagram-modal"
import OverviewContact from "@/components/business/overview/contact"
import OverviewContainer from "@/components/business/overview/container"
import OverviewDescription from "@/components/business/overview/description"
import DisconnectFacebookModal from "@/components/business/overview/disconnect-facebook-modal"
import BusinessExceptionalSchedule from "@/components/business/overview/exceptional-schedule"
import OverviewMedia from "@/components/business/overview/media"
import ReconnectGoogleModal from "@/components/business/overview/reconnect-google-modal"
import OverviewScheduleStandard from "@/components/business/overview/schedule-standard"
import OverviewScore from "@/components/business/overview/score"
import OverviewServiceArea from "@/components/business/overview/service-area"
import OverviewServices from "@/components/business/overview/services"
import BusinessStandardSchedule from "@/components/business/overview/standard-schedule"
import { SkeletonBusinessItem } from "@/components/skeleton/business/view"
import { SkeletonBusinessServices } from "@/components/skeleton/business/view/categ-service"
import { SkeletonBusinessItemDescription } from "@/components/skeleton/business/view/description"
import { SkeletonBusinessHour } from "@/components/skeleton/business/view/hour"
import { SkeletonBusinessLink } from "@/components/skeleton/business/view/link"
import useLogic from "@/hooks/businesses/overview-business/useLogic"
import useUiPageSession from "@/hooks/useUiPageSession"
import useOverviewStore from "@/store/overview"
import OverviewDescriptionStore from "@/store/overview/long-description"
import useMainInfoStore from "@/store/overview/main-info/useMainInfoStore"
import { TestProps } from "@/types/test"
import { isGoogleConnected } from "@/utils"
import { Box, Paper, Stack, SxProps, Typography } from "@mui/material"
import { Fragment, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import CreateBusiness from "../create-business"
import EditAttribute from "../edit-business/edit-attribute"
import EditCategory from "../edit-business/edit-category"
import EditContact from "../edit-business/edit-contact"
import EditDescription from "../edit-business/edit-description"
import EditExceptionalSchedule from "../edit-business/edit-exceptional-schedule"
import EditLink from "../edit-business/edit-link"
import EditMainInfo from "../edit-business/edit-main-info"
import EditMedia from "../edit-business/edit-media"
import EditServices from "../edit-business/edit-service"
import EditStandardSchedule from "../edit-business/edit-standard-schedule"
import EditZone from "../edit-business/edit-zone"
import ListMedia from "../edit-business/list-media"

type Props = TestProps

export const sectionSx = ({ isError }) => ({
    width: "100%",
    ...(isError && { border: "2px solid", borderColor: "error.main" }),
})

const wrapperSx: SxProps = {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    "@media (min-width: 768px)": {
        gap: 3,
    },
    "@media (min-width: 1024px)": {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
    },
}

const BusinessInfo = ({ data }: Props) => {
    const { businessId } = useParams()
    const {
        location,
        isLoading,
        handlePreviousButton,
        t,
        openDisconnectFacebookModal,
        onCloseDisconnectFacebook,
        anchorEl,
        faceboookMenuHandler,
        handleCloseFacebookMenu,
        openFacebookMenu,
        authUrl,
        handeleConnect,
        isLoggedFacebook,
        isLoggedInstagram,
        mediasList,
        totalMediaCount,
        openConnectIgModal,
        disconnectSocial,
        onCloseConnectIg,
        descriptionRef,
        attributRef,
        categoriesRef,
        serviceAreaRef,
        servicesRef,
        regularHoursRef,
        specialHoursRef,
        linksRef,
        contactRef,
        mediaRef,
        chartDonutData,
        missingItem,
        missings,
        scrollTo,
        handleConnectGoogle,
        series,
        profile,
        mainInfoRef,
        loading,
        attributeListTxt,
        isSingleBusiness,
        disconnectProvider,
    } = useLogic({ businessId })

    // const { scheduleStatus } = useLocation()
    const { setLoading } = useOverviewStore()
    const { description } = OverviewDescriptionStore()
    const { name: businessName } = useMainInfoStore()
    const { handleDrawerClose, showPage, open } = useUiPageSession({
        title: "BUSINESSES.TITLE_HEADER",
        page: "businesses",
    })

    const [globalLoading, setGlobalLoading] = useState(true)

    const { needReco, mandatoryListAll, mandatoryCheckContact, mandatoryCheckCategory, mandatoryCheckLink } =
        useMemo(() => {
            const mandatoryListCategory = ["primary_category"]
            const mandatoryListAll = ["name", "country", "address", "primary_category", "description", "logo"]
            const mandatoryListContact = ["name", "country", "address"]
            const mandatoryListLink = []
            const needReco = !isGoogleConnected(location || {})
            if (missingItem.includes("phone") && missingItem.includes("websiteUrl")) {
                mandatoryListAll.push("websiteUrl")
                mandatoryListAll.push("phone")
                mandatoryListLink.push("websiteUrl")
                mandatoryListContact.push("phone")
            }
            const mandatoryCheckContact = missingItem.some((item) => mandatoryListContact.includes(item))
            const mandatoryCheckCategory = missingItem.some((item) => mandatoryListCategory.includes(item))
            const mandatoryCheckLink = missingItem.some((item) => mandatoryListLink.includes(item))
            return {
                needReco,
                mandatoryListCategory,
                mandatoryListAll,
                mandatoryListContact,
                mandatoryListLink,
                mandatoryCheckContact,
                mandatoryCheckCategory,
                mandatoryCheckLink,
            }
        }, [location, missingItem])

    useEffect(() => {
        if (loading.firstLoaded) {
            setGlobalLoading(false)
            return
        }
        if (loading.attribute || loading.locationInfo || loading.media) {
            setGlobalLoading(true)
            return
        }
        setLoading("firstLoaded", true)
        setGlobalLoading(false)
    }, [loading, setLoading])

    return (
        <Fragment>
            {!globalLoading && (
                <ReconnectGoogleModal
                    popupContainer={mainInfoRef}
                    open={needReco}
                    location={location}
                    user={profile}
                    onClose={handlePreviousButton}
                />
            )}

            <OverviewContainer needReco={needReco}>
                {!globalLoading && (
                    <TitleWrapper
                        isSingleBusiness={isSingleBusiness}
                        title={businessName ?? ""}
                        handlePreviousButton={handlePreviousButton}
                        className="title-overview"
                        dataTestId="previous-btn"
                    />
                )}

                {!globalLoading && location?.companyId && (
                    <EstablishmentHeader
                        isSingleBusiness={isSingleBusiness}
                        anchorEl={anchorEl}
                        faceboookMenuHandler={faceboookMenuHandler}
                        handleCloseFacebookMenu={handleCloseFacebookMenu}
                        handleConnectGoogle={handleConnectGoogle}
                        openFacebookMenu={openFacebookMenu}
                        authUrl={authUrl}
                        handeleConnect={handeleConnect}
                        isLoggedFacebook={isLoggedFacebook}
                        isLoggedInstagram={isLoggedInstagram}
                        disconnectSocial={disconnectSocial}
                        isGoogleConnected={isGoogleConnected(location)}
                        disconnectProvider={disconnectProvider}
                    />
                )}
                {globalLoading ? (
                    <Fragment>
                        <SkeletonBusinessItem />
                        <SkeletonBusinessItemDescription />
                        <SkeletonBusinessServices />
                        <SkeletonBusinessHour />
                        <SkeletonBusinessLink />
                    </Fragment>
                ) : (
                    <Box ref={mainInfoRef} className="container">
                        <Stack sx={wrapperSx}>
                            <OverviewContact
                                mandatoryCheck={mandatoryCheckContact}
                                sx={sectionSx({
                                    isError: mandatoryCheckContact,
                                })}
                                isLoading={isLoading}
                            />
                            {/* missings={missingItem} */}
                            <OverviewScore
                                chartDonutData={chartDonutData}
                                missingItem={missingItem}
                                mandatoryList={mandatoryListAll}
                                missings={missings}
                                scrollTo={scrollTo}
                                series={series}
                            />
                        </Stack>
                        <Paper className="section description">
                            <Box className="title-wrapper" ref={descriptionRef}>
                                <Icon name="profile" />
                                <Typography variant="h2">{t("BUSINESSES.DESCRIPTION")}</Typography>
                            </Box>

                            <Box className="section-content">
                                <OverviewDescription
                                    sx={sectionSx({
                                        isError: !description || (description && !description?.short),
                                    })}
                                    isLoading={isLoading}
                                />
                                <OverviewAttribute attributRef={attributRef} data={attributeListTxt} />
                                <OverviewMedia
                                    mediaRef={mediaRef}
                                    totalMediaCount={totalMediaCount}
                                    mediasList={mediasList}
                                />
                            </Box>
                        </Paper>
                        <Paper className="section description category">
                            <Box className="title-wrapper" ref={categoriesRef}>
                                <Icon name="category" />
                                <Typography variant="h2">{t("BUSINESSES.CATEGORIES_AND_SERVICES")}</Typography>
                            </Box>
                            <Box className="paper-wrapper">
                                <Box className="section-content" flexDirection={"row"} gap={2}>
                                    {!isLoading && (
                                        <OverviewBusinessCategory
                                            mandatoryCheck={mandatoryCheckCategory}
                                            meta={location?.meta}
                                        />
                                    )}
                                    <OverviewServices servicesRef={servicesRef} isLoading={isLoading} data={data} />
                                </Box>
                                <OverviewServiceArea
                                    serviceAreaRef={serviceAreaRef}
                                    isLoading={isLoading}
                                    data={data}
                                />
                            </Box>
                        </Paper>

                        <Paper className="section description">
                            <Box className="title-wrapper">
                                <Icon name="clock" />
                                <Typography variant="h2">{t("BUSINESSES.SCHEDULE.OPENING")}</Typography>
                            </Box>
                            <Box>
                                <OverviewScheduleStandard isLoading={isLoading} />
                            </Box>
                            <Box className="schedules">
                                <BusinessStandardSchedule regularHoursRef={regularHoursRef} isLoading={isLoading} />
                                <BusinessExceptionalSchedule specialHoursRef={specialHoursRef} />
                            </Box>
                        </Paper>
                        <AdditionalInfo
                            linksRef={linksRef}
                            contactRef={contactRef}
                            isLoading={isLoading}
                            sx={sectionSx({
                                isError: mandatoryCheckLink,
                            })}
                            mandatoryCheck={mandatoryCheckLink}
                        />
                    </Box>
                )}
            </OverviewContainer>
            <CreateBusiness open={showPage === "create" && open} onClose={handleDrawerClose} />
            <DisconnectFacebookModal
                open={openDisconnectFacebookModal}
                onClose={onCloseDisconnectFacebook}
                provider={disconnectProvider}
            />
            <EditMainInfo open={showPage === "edit-main-info" && open} onClose={handleDrawerClose} />
            <EditCategory open={showPage === "edit-category" && open} onClose={handleDrawerClose} />
            <EditLink open={showPage === "edit-link" && open} onClose={handleDrawerClose} />
            <EditDescription open={showPage === "edit-description" && open} onClose={handleDrawerClose} />
            <EditStandardSchedule open={showPage === "edit-standard-schedule" && open} onClose={handleDrawerClose} />
            <EditExceptionalSchedule
                open={showPage === "edit-exceptional-schedule" && open}
                onClose={handleDrawerClose}
            />
            <EditZone open={showPage === "edit-zone" && open} onClose={handleDrawerClose} />
            <EditServices open={showPage === "edit-service" && open} onClose={handleDrawerClose} />
            <EditAttribute open={showPage === "edit-attribute" && open} onClose={handleDrawerClose} />
            <EditContact open={showPage === "edit-contact" && open} onClose={handleDrawerClose} />
            <ListMedia
                open={(showPage === "list-media" || showPage === "edit-media") && open}
                onClose={handleDrawerClose}
            />
            <EditMedia open={showPage === "edit-media" && open} onClose={handleDrawerClose} />
            <ConnectInstagramModal onClose={onCloseConnectIg} open={openConnectIgModal} />
        </Fragment>
    )
}

export default BusinessInfo
