import NotificationStore from "@/store/notification"
import { captureException } from "@sentry/react"
import i18next from "i18next"

class ErrorHandlerRequestService {
    asyncHandlerRequestError = async (callback: any, onError?: any) => {
        try {
            const resp = await callback()
            return resp
        } catch (error) {
            if (error?.response) {
                if (typeof onError === "function") {
                    return onError(error?.response)
                }
                if (typeof error.response?.data === "object") {
                    return {
                        ...error.response?.data,
                        status_code: error?.response?.status,
                    }
                }
                return error.response?.data ?? { error: "SYSTEM_ERROR.INVALID_REQUEST" }
            }
            this.unHandledRequestError(error)
            return { error: "SYSTEM_ERROR.INVALID_REQUEST" }
        }
    }

    unHandledRequestError = (error) => {
        const { update: showNotification } = NotificationStore.getState()
        showNotification({ type: "ERROR", message: i18next.t("SYSTEM_ERROR.INVALID_REQUEST"), show: true })
        captureException(error, { level: "error" })
    }
}

export default new ErrorHandlerRequestService()
