import { SelectType } from "@/types/select"
import { getUpdatedOptions } from "@/utils/index"
import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material"
import { useMemo, useState } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import CustomTooltip from "../Tooltip"
import BaseInput from "../input"
import { iconBtnSx } from "../page-container/list-container/title-wrapper/styles"
import { defaultSx, inputLabelSx, labelHeaderSelect } from "./style"

interface BaseSelectProps {
    id?: string
    label?: string
    sx?: any
    defaultValue?: string
    className?: string
    required?: boolean
    controlName?: string
    control?: any
    options?: SelectType[]
    optionsAvailable?: SelectType[]
    secondOptions?: SelectType[]
    listSubheaderOne?: string
    listSubheaderTwo?: string
    disabled?: boolean
    register?: any
    dataTestId?: string
    onItemClicked?: (item: any) => void
    formStateError?: boolean
    multiSelect?: boolean
    open?: boolean
    onSelectionChange?: (selected: any) => void
    onClearClicked?: () => void
    selected?: string[]
    mobileWidth?: number
}

const BaseSelect = ({
    id,
    label,
    sx,
    defaultValue,
    className,
    required = false,
    controlName,
    control,
    options,
    secondOptions,
    listSubheaderOne,
    listSubheaderTwo,
    disabled,
    register,
    onItemClicked,
    dataTestId,
    multiSelect,
    formStateError,
    onSelectionChange,
    onClearClicked,
    selected,
    mobileWidth = 768,
    optionsAvailable,
    ...rest
}: BaseSelectProps) => {
    const { t } = useTranslation()
    const [openTextFilter, setOpenTextFilter] = useState(false)
    const otherSelectedNumber = useMemo(() => {
        return selected?.length > 1 ? selected.length - 1 : 0
    }, [selected])

    return (
        <FormControl
            fullWidth
            className={className}
            variant="filled"
            required={required}
            {...rest}
            {...register}
            disabled={disabled}
            focused={false}
            aria-label="custom-select"
            sx={{
                position: "relative",
            }}
        >
            {!openTextFilter && (
                <InputLabel sx={{ ...inputLabelSx(formStateError) }} error={formStateError} id={id}>
                    {label}
                </InputLabel>
            )}

            {openTextFilter && (
                <BaseInput
                    label={label}
                    error={formStateError}
                    sx={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        left: 0,
                        zIndex: 10,
                    }}
                />
            )}

            {control && (
                <Controller
                    render={({ field, fieldState }) => (
                        <Select
                            defaultValue={field.value || defaultValue}
                            labelId={id}
                            value={field.value || defaultValue}
                            MenuProps={{
                                className: "select-menu-wrapper",
                                sx: {
                                    width: 360,
                                    "& .MuiList-root": {
                                        maxWidth: "100%",
                                    },
                                },
                                classes: { paper: "select-paper" },
                            }}
                            error={!!fieldState?.error}
                            sx={{
                                ...defaultSx({
                                    noBottomBorder: false,
                                    showLabelStar: true,
                                    error: !!fieldState?.error,
                                }),
                                ...sx,
                            }}
                            {...field}
                            renderValue={(selected: any) => (
                                <Typography className="first-selected" component="span">
                                    {options.find((option) => option.value === selected)?.label}
                                </Typography>
                            )}
                        >
                            {(!options || options?.length === 0) && (
                                <MenuItem value="" disabled>
                                    <Typography>{t("AUTOCOMPLETE.NO_OPTIONS")}</Typography>
                                </MenuItem>
                            )}
                            {getUpdatedOptions(options, optionsAvailable)?.map((option: any, key) => (
                                <MenuItem
                                    key={`${String(key)}`}
                                    value={option.value}
                                    data-e2e="filter-option"
                                    sx={{
                                        gap: "8px",
                                        "&.Mui-selected": {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                    disabled={option?.disabled}
                                >
                                    {option.prefix && <Typography component={"span"}>{option.prefix}</Typography>}
                                    {/* {!option.shortAddress && <span>{option.label}</span>} */}
                                    {![", ", "undefined, undefined"].includes(option.shortAddress) && (
                                        <List
                                            sx={{
                                                "&.MuiListItem-root": {
                                                    borderRadius: 0,
                                                    border: "none",
                                                    boxShadow: "none",
                                                    display: "block",
                                                    background: "none",
                                                },
                                            }}
                                        >
                                            <ListItemText
                                                disableTypography
                                                primary={option.label}
                                                sx={{
                                                    fontSize: "16px",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                }}
                                            />
                                            {![", ", "undefined, undefined"].includes(option.shortAddress) && (
                                                <ListItemText
                                                    disableTypography
                                                    primary={option.shortAddress}
                                                    sx={{
                                                        color: "#68738D",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                />
                                            )}
                                        </List>
                                    )}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    name={controlName || ""}
                    control={control}
                    defaultValue={defaultValue}
                />
            )}
            {!control && !multiSelect && (
                <Select
                    value={defaultValue}
                    labelId={id}
                    MenuProps={{
                        className: "select-menu-wrapper",
                        sx: {
                            "& .MuiList-root": {
                                maxWidth: "100%",
                            },
                        },
                    }}
                    sx={{
                        ...defaultSx({
                            noBottomBorder: false,
                            showLabelStar: true,
                        }),
                        ...sx,
                    }}
                >
                    {(!options || options?.length === 0) && (
                        <MenuItem value="" sx={{ justifyContent: "center" }} disabled>
                            <Typography component={"span"} sx={{ color: "action.active" }}>
                                {t("AUTOCOMPLETE.NO_OPTIONS")}
                            </Typography>
                        </MenuItem>
                    )}
                    {options?.map((option, key) => (
                        <MenuItem
                            value={option.value}
                            key={`${String(key)}`}
                            sx={{
                                paddingBottom: "8px",
                                paddingTop: "8px",
                                gap: "8px",
                            }}
                            onClick={() => {
                                if (typeof onItemClicked === "function") {
                                    onItemClicked(option)
                                }
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            )}

            {multiSelect && (
                <Select
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    key={"multiple-select-checkbox"}
                    multiple
                    value={selected}
                    onChange={onSelectionChange}
                    onOpen={() => setOpenTextFilter(true)}
                    onClose={() => setOpenTextFilter(false)}
                    sx={{
                        ...defaultSx({
                            noBottomBorder: false,
                            showLabelStar: true,
                        }),
                        ...sx,
                    }}
                    renderValue={(selected: any) => (
                        <Stack gap={1} flexDirection="row" sx={{ width: "calc(100% - 75px)" }}>
                            <Typography className="first-selected" component="span">
                                {selected[0]?.label}
                            </Typography>
                            {otherSelectedNumber > 0 && (
                                <Typography width={selected[0]?.label?.length > 20 ? 75 : 30} component="span">
                                    +{otherSelectedNumber}
                                </Typography>
                            )}
                        </Stack>
                    )}
                    endAdornment={
                        selected?.length > 0 && (
                            <Stack
                                flexDirection={"row"}
                                marginRight={3}
                                className="text-secondary"
                                sx={{ position: "absolute", right: "8px" }}
                            >
                                <CustomTooltip title={t("BUSINESSES.ERASE_TEXT")}>
                                    <IconButton
                                        sx={iconBtnSx}
                                        onClick={onClearClicked}
                                        className="text-clear-icon"
                                        disabled={disabled}
                                    >
                                        <Icon name="close" />
                                    </IconButton>
                                </CustomTooltip>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ marginX: "8px", height: "24px", alignSelf: "center" }}
                                />
                            </Stack>
                        )
                    }
                    MenuProps={{
                        className: options && secondOptions ? "select-menu-wrapper-rate" : "select-menu-wrapper",
                        sx: {
                            width: 360,
                            top: 0,
                            "& .MuiList-root": {
                                columnCount: options && secondOptions ? 2 : 1,
                                paddingTop: options && secondOptions ? "8px" : 1,
                                columnGap: 0,
                                maxWidth: "100%",
                                "@media(max-width: 767px)": {
                                    columnCount: 1,
                                },
                            },
                            "& .MuiMenuItem-root": {
                                padding: "0px 8px 0px 8px",
                                "& .MuiListItemText-root": {
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                },
                                "&.Mui-selected": {
                                    backgroundColor: "transparent",
                                },
                                "@media(max-width: 767px)": {
                                    borderRight: "none",
                                },
                                "&.Mui-focusVisible": {
                                    backgroundColor: "transparent",
                                },
                            },
                        },
                    }}
                >
                    {(!options || options?.length === 0) && (
                        <MenuItem value="" sx={{ justifyContent: "center" }} disabled>
                            <Typography component={"span"} sx={{ color: "action.active" }}>
                                {t("AUTOCOMPLETE.NO_OPTIONS")}
                            </Typography>
                        </MenuItem>
                    )}
                    {listSubheaderOne && (
                        <ListSubheader sx={{ ...labelHeaderSelect("left") }}>{listSubheaderOne}</ListSubheader>
                    )}
                    {secondOptions &&
                        options?.map((value: any, k) => (
                            <MenuItem
                                sx={{
                                    borderRight: "1px solid #5B7DD829",
                                }}
                                key={`${String(k)}`}
                                value={value}
                                data-e2e="filter-option"
                            >
                                <Checkbox
                                    checked={selected.findIndex((item: any) => item.value === value.value) > -1}
                                />
                                <ListItemText primary={value.label} sx={{ textWrap: "pretty" }} />
                            </MenuItem>
                        ))}
                    {options && secondOptions && (
                        <Box
                            component="span"
                            sx={{
                                "@media (min-width: 768px)": {
                                    display: "block",
                                    breakAfter: "column",
                                },
                            }}
                        ></Box>
                    )}

                    {listSubheaderTwo && (
                        <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{
                                marginX: "8px",
                                "@media (min-width: 768px)": { display: "none" },
                            }}
                        />
                    )}
                    {listSubheaderTwo && (
                        <ListSubheader sx={{ ...labelHeaderSelect("right") }} className="aside-item">
                            {listSubheaderTwo}
                        </ListSubheader>
                    )}

                    {secondOptions?.map((value: any, k) => (
                        <MenuItem key={`${String(k)}`} className="aside-item" value={value} data-e2e="filter-option">
                            <Checkbox checked={selected.findIndex((item: any) => item.value === value.value) > -1} />
                            <ListItemText
                                primary={value.label}
                                sx={{ textWrap: "pretty", whiteSpace: "normal !important" }}
                            />

                            {value.prefix && <Typography component={"span"}>{value.prefix}</Typography>}
                        </MenuItem>
                    ))}
                    {options &&
                        !secondOptions &&
                        options.map((value: any, k) => (
                            <MenuItem
                                key={`${String(k)}`}
                                value={value}
                                data-e2e="filter-option"
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                <Checkbox
                                    checked={selected.findIndex((item: any) => item.value === value.value) > -1}
                                />
                                <List
                                    sx={{
                                        maxWidth: "calc(100% - 42px) !important",
                                        "&.MuiListItem-root": {
                                            borderRadius: 0,
                                            border: "none",
                                            boxShadow: "none",
                                            display: "block",
                                            background: "none",
                                        },
                                    }}
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={value.label}
                                        sx={{
                                            fontSize: "16px",
                                        }}
                                    />

                                    {![", ", "undefined, undefined"].includes(value.shortAddress) && (
                                        <ListItemText
                                            disableTypography
                                            primary={value.shortAddress}
                                            sx={{
                                                color: "#68738D",
                                            }}
                                        />
                                    )}
                                </List>
                            </MenuItem>
                        ))}
                </Select>
            )}
        </FormControl>
    )
}

export default BaseSelect
