import { ApiService } from "@/api"
import env from "@/config/env"
import { encodeBase64, objectToQueryString } from "@/utils"
import { useCallback, useMemo } from "react"

const useAuthInstagram = ({ location }) => {
    const isLoggedInstagram = useMemo(() => {
        if (location?.meta?.instagram_id) return true

        return false
    }, [location])

    const openInstagramAndFacebookAccount = useCallback(({ companyId, user_id, location_id, multiples_accounts }) => {
        const bearer = ApiService.getToken()?.token
        const b64 = encodeBase64(
            `companyId=${companyId}&user_id=${user_id}&location_id=${location_id}&cb=${window.location.origin}/businesses&auth_type=instagram&bearer=${bearer}`
        )

        const query = objectToQueryString({
            client_id: env.FACEBOOK_APP_ID,
            redirect_uri: `${env.AUTH_URL}`,
            response_type: "token",
            scope: `${env.INSTAGRAM_SCOPE},`,
            state: `${b64}`,
        })
        const authUrl = multiples_accounts
            ? `${env.AUTH_URL}#state=${b64}`
            : "https://www.facebook.com/v18.0/dialog/oauth?" + query
        window.location.href = authUrl
    }, [])

    return {
        isLoggedInstagram,
        openInstagramAndFacebookAccount,
    }
}

export default useAuthInstagram
