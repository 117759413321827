import { ResellerApi } from "@/api"
import { PlaceType } from "@/components/autocomplete/address-autocomplete/types"
import { PROVINCES_CA } from "@/data/provinces"
import { US_STATES_CODE } from "@/data/states"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import useCountryList from "@/hooks/useCountryList"
import useFormater from "@/hooks/useFormater"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useProfileInfo from "@/hooks/useProfileInfo"
import useZipInputLogic from "@/hooks/zip-input/useZipInputLogic"
import useMainInfoStore from "@/store/overview/main-info/useMainInfoStore"
import useScoreStore from "@/store/overview/score/useScoreStore"
import UiPageSessionStore from "@/store/ui-page-session"
import { FormValues, LocationType } from "@/types/businesses"
import { formatZipByCountry } from "@/utils"
import { CountryCode } from "libphonenumber-js"
import cloneDeep from "lodash.clonedeep"
import deburr from "lodash.deburr"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler } from "react-hook-form"
import { useTranslation } from "react-i18next"
import useBusinessLogic from "../useBusinessLogic"
import useEditMainInfo from "./useEditMainInfo"
import useMainInfoForm from "./useMainInfoForm"

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { location, updateLocation } = useLocation()
    const { t } = useTranslation()
    const { countryList } = useCountryList()
    const [companies, setCompanies] = useState([])
    const { notif } = useNotification()
    const { parseAddress, internationalPhone } = useFormater()
    const [loading, setLoading] = useState(false)
    const [phone, setPhone] = useState({
        isInvalid: false,
        isRequired: false,
        zipValid: false,
    })
    const { formState, formValues, control, handleSubmit, register, reset, setFocus, setValue, trigger } =
        useMainInfoForm()
    const { isPhoneValid, isPhoneRequired } = usePhoneInputLogic()
    const [openLogoModal, setOpenLogoModal] = useState(false)
    const {
        isZipValid,
        zipInputValue,
        zipIsRequired,
        checkZipIsValid,
        setZipInputValue,
        setIsZipValid,
        setZipIsRequired,
    } = useZipInputLogic()
    const { showPage } = UiPageSessionStore()
    const { previousItem, langOfCountryName, setPreviousItem } = useEditMainInfo()
    const { companyName, isAddressVisible, websiteUrl, setMainInfo } = useMainInfoStore()
    const [addressVisible, setAddressVisible] = useState(isAddressVisible)

    const [defaultFlag, setDefaultFlag] = useState<CountryCode>("CA")
    const [logo, setLogo] = useState<File>()
    const { isAdminCompany } = useProfileInfo()
    const isMainInfoPage = useMemo(() => location && showPage === "edit-main-info", [location, showPage])
    const haveLogo = useMemo(() => {
        return !!(location?.logo || logo)
    }, [logo, location?.logo])

    const serviceAreaValue = useMemo(() => {
        return {
            businessType: addressVisible ? "CUSTOMER_AND_BUSINESS_LOCATION" : "CUSTOMER_LOCATION_ONLY",
        }
    }, [addressVisible])

    const defaultValue = useMemo(() => {
        const data = previousItem?.companyId === location?.companyId ? previousItem : { ...location }
        if (data && typeof data === "object") {
            data["phone"] =
                data?.phone && data?.countryPhone ? internationalPhone(data?.phone, data?.countryPhone) : data?.phone
            data["countryPhone"] = data?.countryPhone || "CA"
            data["country"] = data?.country || "CA"
            data["company"] = data?.company ? data.company : data.companyId
            let address = data?.address || ""
            if (data?.address === "" && data?.city && data?.country) {
                address = parseAddress({
                    region: data?.country !== "FR" && data?.country !== "BE" ? (data.region ? data.region : "") : "",
                    countryCode: data?.country,
                    address: data?.address,
                    city: data.city,
                    zip: data.zip,
                })
            }
            data["address"] = address
        }
        return data
    }, [previousItem, location, internationalPhone, parseAddress])

    const openLogo = useCallback(() => setOpenLogoModal(true), [])
    const closeLogo = useCallback(() => setOpenLogoModal(false), [])
    const { refreshScoring } = useScoreStore({})

    const [addressValuePlace, setAddressValuePlace] = useState<PlaceType>({
        description: "",
        structured_formatting: null,
    })

    useEffect(() => {
        setPreviousItem({ ...cloneDeep(location), serviceArea: null })
        setAddressVisible(isAddressVisible)
        setLogo(null)
    }, [location, isAddressVisible, setPreviousItem])

    const handleAddressChange = useCallback(
        (data: any) => {
            setValue("address", data.address)
            setValue("city", data.city)
            if (formValues.country !== "FR" && formValues.country !== "BE") {
                setValue("region", data.province_code ? data.province_code : data.region)
            } else {
                setValue("region", "default", { shouldValidate: true })
            }
            setValue("zip", data.zip)
            setZipInputValue(data.zip)
            trigger()
        },
        [trigger, formState, formValues?.country]
    )

    const handleAddressInputChange = (address) => {
        setValue("address", address)
        trigger("address")
    }

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        setPreviousItem({
            address: formValues?.address,
            zip: formValues?.zip,
            city: formValues?.city,
            region: formValues?.region,
            country: formValues?.country,
            name: formValues?.name,
            companyId: formValues?.company,
            phone: formValues?.phone,
            addressVisible,
            countryPhone: defaultFlag,
        })
    }, [addressVisible, defaultFlag, formValues])

    const provinceList = useMemo(() => {
        return PROVINCES_CA.map((code) => ({ label: t(`PROVINCE.${code}`), value: code })).sort((a, b) =>
            deburr(a.label) > deburr(b.label) ? 1 : -1
        )
    }, [t])

    const stateList = useMemo(() => {
        return US_STATES_CODE.map((code) => ({ label: t(`PROVINCE.${code}`), value: code })).sort((a, b) =>
            deburr(a.label) > deburr(b.label) ? 1 : -1
        )
    }, [t])

    useEffect(() => {
        if (defaultValue) {
            setValue("country", defaultValue.country)
            setDefaultFlag(defaultValue.countryPhone)
            setValue("phone", defaultValue.phone || "")
            setCompanies([
                {
                    label: companyName,
                    value: defaultValue.company,
                },
            ])
            setValue("countryPhone", defaultValue.countryPhone)
            setValue("company", defaultValue.company)
            setAddressValuePlace({
                description: defaultValue.address || "",
                structured_formatting: defaultValue.address || "",
            })
            setValue("name", defaultValue.name)
            handleAddressChange(defaultValue)
            setValue("city", defaultValue.city || "")
            setValue(
                "region",
                defaultValue.region && defaultValue.country !== "FR" && defaultValue.country !== "BE"
                    ? defaultValue.region
                    : ""
            )
            setValue("zip", defaultValue.zip || "")
            setZipInputValue(defaultValue.zip)
            trigger()
            if (!defaultValue.zip) setZipIsRequired(true)
            if (!defaultValue.phone && !websiteUrl) {
                setPhone({ isInvalid: false, isRequired: true, zipValid: false })
            }
            if (defaultValue.country === "FR" || defaultValue.country === "BE") {
                setValue("region", "default", { shouldValidate: true })
            }
        }
    }, [defaultValue, companyName, websiteUrl])

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        const previousFormValue = {
            address: location?.address,
            zip: location?.zip,
            city: location?.city,
            region: location?.region,
            country: location?.country,
            name: location?.name,
            company: location?.companyId,
            phone: location?.phone,
            countryPhone: defaultFlag,
        }
        reset(previousFormValue)
        setPreviousItem({
            ...previousFormValue,
            companyId: location?.companyId,
            logo: location?.logo,
        })
        setAddressValuePlace({ description: defaultValue.address, structured_formatting: defaultValue.address })
        setPhone({ isInvalid: false, isRequired: false, zipValid: true })
        setIsZipValid(true)
        setLogo(null)
        setAddressVisible(isAddressVisible)
    }, [location, serviceAreaValue, defaultFlag, isAddressVisible])

    const submitLocationWithoutLogo = useCallback(
        async (body: LocationType) => {
            for (const prop in body) {
                if (!body[prop] && prop != "phone") {
                    delete body[prop]
                }
            }
            const response = await ResellerApi.updateLocation({ ...body })
            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                // setUiPageSession({ refreshItems: true })
                notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                updateLocation(response)
                await refreshScoring()
                navigateTo("detail")
                //setMainInfo(response)
            }
            setLoading(false)
        },
        [navigateTo, notif, setMainInfo, t, refreshScoring]
    )

    const onSubmit: SubmitHandler<any> = useCallback(
        async (data: FormValues) => {
            setLoading(true)
            const body = { ...location }
            let pIsInvalid = false
            let pIsRequired = false
            let nRequired = false
            let zipValid = true
            setPhone((prev) => ({ ...prev, isInvalid: false, isRequired: false }))
            setIsZipValid(true)

            if (data.name === null || data.name === "" || data.name === " ") {
                nRequired = true
            }
            body["address"] = data.address
            body["city"] = data.city
            body["country"] = data.country
            body["phone"] = data.phone
            body["region"] = data.country === "FR" || data.country === "BE" ? "" : data.region
            body["name"] = data.name
            body["serviceArea"] = {
                ...location.serviceArea,
            }
            body["zip"] = zipInputValue
            if (/^\+\d{1,4}$/.test(data.phone)) {
                body["phone"] = ""
            }
            body["countryPhone"] = defaultFlag

            body["serviceArea"]["businessType"] = serviceAreaValue.businessType

            if (!isPhoneValid(body.phone)) {
                pIsInvalid = true
                setPhone((prev) => ({ ...prev, isInvalid: true }))
            }
            if (isPhoneRequired(body.phone)) {
                pIsRequired = true
                setFocus("phone")
                setPhone((prev) => ({ ...prev, isRequired: true }))
            }
            if (!checkZipIsValid(body.zip, body.country)) {
                zipValid = false
                setIsZipValid(false)
            }
            if (formState.isValid && !nRequired && zipValid && !pIsInvalid && !pIsRequired) {
                if (logo) {
                    const responseFile = await ResellerApi.postFile({ location_id: body.id, file: logo })
                    if (responseFile?.error) {
                        notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                    } else {
                        body["logo"] = responseFile.url
                        submitLocationWithoutLogo(body)
                    }
                } else {
                    submitLocationWithoutLogo(body)
                }
            } else {
                setLoading(false)
            }
        },
        [location, defaultFlag, zipInputValue, formState, notif, serviceAreaValue?.businessType]
    )

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        if (errors) {
            if (errors.phone) {
                setPhone((prev) => ({ ...prev, isRequired: true }))
            }
            if (errors.zip) {
                setZipIsRequired(true)
            }
        }
    }

    const zipInputHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value
            const formattedZip = formatZipByCountry(value, formValues?.country)
            if (formattedZip || formattedZip === "") {
                setZipInputValue(formattedZip)
                setValue("zip", formattedZip)
                trigger(["zip"])
            }
        },
        [formValues?.country, setValue, setZipInputValue, trigger]
    )

    const handlePhoneChange = useCallback((info: any) => {
        if (info?.countryCode) {
            setDefaultFlag(info.countryCode)
        }
    }, [])

    useEffect(() => {
        setPhone((prev) => ({ ...prev, isRequired: false }))
        if (isMainInfoPage) {
            if (!websiteUrl && (!formValues?.phone || /^\+\d{1,4}$/.test(formValues?.phone)))
                setPhone((prev) => ({ ...prev, isRequired: true }))
        }
    }, [formValues?.phone, websiteUrl])

    useEffect(() => {
        if (formValues?.country !== defaultValue?.country) {
            setValue("city", "")
            setValue("region", null)
            setValue("zip", "")
            setZipInputValue("")
            handleAddressInputChange("")
            setAddressValuePlace({ description: "", structured_formatting: null })

            setValue("region", "", { shouldValidate: false })
        }
        setTimeout(() => trigger())
    }, [formValues?.country])

    return {
        companies,
        previousItem,
        zipInputValue,
        countryList,
        isZipValid,
        addressValuePlace,
        stateList,
        provinceList,
        loading,
        location,
        formState,
        langOfCountryName,
        defaultFlag,
        openLogoModal,
        logo,
        phone,
        zipIsRequired,
        haveLogo,
        formValues,
        control,
        addressVisible,
        handlePreviousButton,
        handleSubmit,
        onSubmit,
        onErrors,
        setAddressValuePlace,
        handleAddressChange,
        handleAddressInputChange,
        register,
        zipInputHandler,
        setAddressVisible,
        handleCancelButton,
        handlePhoneChange,
        setOpenLogoModal,
        openLogo,
        closeLogo,
        setLogo,
        trigger,
        setValue,
    }
}

export default useLogic
