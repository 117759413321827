import Icon from "@/components/base/Icon"
import { Stack, Typography } from "@mui/material"
import { useStyles } from "../../style"

export const ContentSuccess = ({ message, icon }) => {
    const { classes } = useStyles()
    return (
        <Stack className={classes.contentSuccess} width={"100%"}>
            <Icon name={icon} sx={{ width: 240, height: 185, margin: "0 auto" }} />
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
                {message}
            </Typography>
        </Stack>
    )
}
