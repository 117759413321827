import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    quillBloc: {
        fontFamily: "Poppins",
        "& .open-model": {
            "& .ql-editor": {
                minHeight: "158px",
            },
            "&.preview": {
                "& .ql-editor": {
                    height: "158px",
                },
            },
        },
        "&.email-editor": {
            "& .section-emoji": {
                borderRadius: "24px",
            },
        },
        "&.sms-editor": {
            "& .ql-editor": {
                marginBottom: 70,
            },
            "& .ql-container.ql-snow ": {
                borderRadius: "24px 24px 4px 24px",
                background: "rgba(172, 182, 229, 0.16)",
            },
        },
        "&.hide-toolbar": {
            "& .ql-toolbar": {
                display: "none",
            },
            "& .ql-editor": {
                padding: "0px 15px 0px 15px",
                marginTop: 35,
                minHeight: "100px",
            },
        },
        "& .quill": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },

        "& .ql-toolbar.ql-snow": {
            border: "none",
            padding: "0px 8px",
            "& .ql-stroke": {
                stroke: theme.palette.action.active,
            },
        },

        "& .ql-container.ql-snow ": {
            border: "none",
            borderRadius: "8px",
            minHeight: "68px",
            font: "inherit",
            letterSpacing: "inherit",
            color: "currentColor",
            padding: "4px 0 5px",
            boxSizing: "content-box",
            background: "none",
            margin: "0",
            display: "block",
            width: "100%",
            backgroundColor: "rgba(172, 182, 229, 0.16)",
            borderBottom: "1px solid rgba(91, 125, 216, 0.12)",
        },
        "& .ql-editor": {
            padding: "12px 15px 0px 15px",
            marginBottom: 46,
            overflow: "auto",
            "@media (max-width: 767px)": {
                marginBottom: 70,
            },
            p: {
                lineHeight: "normal",
            },
            "&.ql-blank::before": {
                color: theme.palette.action.active,
                fontStyle: "normal",
            },
            "& .mention": {
                padding: "0px 6px",
                margin: "0px 1px",
                alignItems: "center",
                borderRadius: "100px",
                background: "rgba(0, 0, 0, 0.08)",
                border: "none",
                wordBreak: "break-all",
            },
        },
        "& .ql-mention-list-container": {
            zIndex: 1,
            width: "max-content",
            boxShadow: "0px 8px 10px 1px rgba(0, 4, 31, 0.16),0px 3px 14px 2px rgba(0, 4, 31, 0.16)",
            borderRadius: "8px",
            "& .ql-mention-list": {
                background: "white",
                borderRadius: "5px",
                margin: 0,
                listStyle: "none",
                padding: "5px 0px",
                "&:hover": {
                    cursor: "pointer",
                },
            },
            "& .ql-mention-list-item": {
                padding: "5px 25px",
                "&.selected": {
                    background: "aliceblue",
                },
            },
        },
        "& .fake-placeholder": {
            position: "absolute",
            top: 32,
            left: 14,
            right: 12,
            color: "#68738D",
            "&.sms": {
                top: 0,
                left: 18,
            },
            "& .link": {
                color: "#0180FF",
                textDecoration: "underline",
                "&:hover": {
                    cursor: "pointer",
                },
            },
        },
        "& .section-emoji": {
            position: "absolute",
            flexDirection: "row",
            bottom: 0,
            left: 0,
            height: "auto",
            width: "100%",
            boxSizing: "border-box",
            padding: "0px 16px 16px 16px;",
            gap: 8,
            borderRadius: "24px 24px 4px 24px",
            background: "transparent",
            "& .MuiIconButton-root": {
                padding: 0,
            },
            "& .bloc-emoji": {
                position: "relative",
                "& .MuiIconButton-root ": {
                    padding: "0px",
                },
            },
        },
        "& .label-editor": {
            position: "absolute",
            top: "16px",
            left: "16px",
            "& .MuiFormLabel-asterisk": {
                color: theme.palette.primary.main,
            },
        },
        "&.disable-url": {
            '& .ql-mention-list-item[data-value="url"]': {
                display: "none",
            },
        },
    },
    missingLink: {
        flexDirection: "row",
        gap: 16,
        border: "1px solid #2EAFF7",
        padding: "16px",
        borderRadius: "8px",
        marginTop: 16,
        "@media (max-width: 767px)": {
            padding: "8px",
            gap: 8,
            marginTop: 8,
        },
    },
    blocModel: {
        width: "100%",
        "@media (max-width: 767px)": {
            margin: "auto",
            maxWidth: "calc(100% - 32px)",
        },
        "& .div-model-paper": {
            width: "inherit",
            maxWidth: "100%", // Ensure it doesn't exceed the screen width
            padding: 0,
            "@media (max-width: 767px)": {
                left: "0 !important",
            },
        },
        "& .content-model": {
            padding: 16,
            "& .header-model": {
                flexDirection: "row",
                justifyContent: "end",

                "&:hover, &:focus": {
                    background: "transparent",
                },
            },
            "& .item-model": {
                padding: "8px",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: "#E5EAF9",
                "&:hover": {
                    background: "#E1F0FF",
                    cursor: "pointer",
                },
                "& .mention-blue": {
                    color: theme.palette.action.active,
                    p: {
                        margin: 0,
                    },
                    "& .mention": {
                        border: "none",
                        color: theme.palette.primary.main,
                    },
                },
            },
            "& .item-model:last-child": {
                borderBottom: "none",
            },
            "& .list-model": {
                maxHeight: "190px",
                overflowX: "auto",
            },
            "& .btn-edit-model": {
                border: "none",
                boxShadow: "none",
            },
        },
    },
}))

export const menuSx: SxProps = {
    "& .MuiBackdrop-root": { opacity: "0 !important" },
    "& .MuiPaper-root": {
        padding: 0,
        "& ul": {
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 0,
            "& li": {
                padding: 0,
                gap: 1,
                fontSize: 15,
                minHeight: 26,
                "& span": { height: 26 },
            },
        },
    },
}

export default useStyles
