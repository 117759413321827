import Icon from "@/components/base/Icon"
import { SocialsPostService } from "@/services"
import useLocationStore from "@/store/location"
import SocialStore from "@/store/social"
import SocialPopupStore from "@/store/social-popup"
import { Alert, AlertTitle, Button, Collapse, IconButton, Stack, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const AlertSocialAccountNotConnected = () => {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation()
    const { selectedBusinesses, update } = SocialStore()
    const { showPopupContent } = SocialPopupStore()
    const { update: updateLocation, businesses } = useLocationStore()

    const handleShowPostAlert = useCallback(async () => {
        await SocialsPostService.hidePostAlert(selectedBusinesses)
        update({ showPostAlert: false })
        updateLocation({
            businesses: businesses.map((business) => {
                if (selectedBusinesses.includes(business.value)) {
                    return {
                        ...business,
                        showPostAlert: false,
                    }
                }
                return business
            }),
        })
        setOpen(false)
    }, [selectedBusinesses, update, updateLocation, businesses])

    return (
        <Collapse in={open}>
            <Alert
                sx={{ flexDirection: "row", boxShadow: "none", gap: 1 }}
                severity="warning"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        <Icon name="close" />
                    </IconButton>
                }
            >
                <AlertTitle>{t("SOCIALS.ACCOUNT_NOT_CONNECTED_WARNING")}</AlertTitle>
                <Stack gap={2} flexDirection={"row"} flexWrap={"wrap"}>
                    <Button
                        sx={{
                            backgroundColor: "#663C0011",
                            color: "#663C00",
                            "&:hover": {
                                backgroundColor: "#663C0011",
                            },
                        }}
                        onClick={() => {
                            showPopupContent("connexion_details")
                        }}
                    >
                        {t("SOCIALS.SHOW_DISCONNECTED_ACCOUNT")}
                    </Button>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                        <Typography variant="body2" color={"#663C00"}>
                            {t("SOCIALS.IT_S_NORMAL")},
                        </Typography>
                        <Link
                            to={"#"}
                            style={{ color: "#663C00" }}
                            onClick={async (e) => {
                                e.preventDefault()
                                handleShowPostAlert()
                            }}
                        >
                            <Typography variant="body2" color={"#663C00"}>
                                {t("SOCIALS.DO_NOT_DISPLAY_ANYMORE")?.toLowerCase()}
                            </Typography>
                        </Link>
                    </Stack>
                </Stack>
            </Alert>
        </Collapse>
    )
}
