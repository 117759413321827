import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    container: {
        top: "0",
        position: "absolute",
        "& .MuiModal-backdrop": {
            top: "0",
            // "@media (max-width: 767px)": {
            //     top: "142px",
            // },
        },
        ".btn": {
            gap: 1,
            width: "100%",
            border: "none",
            outline: "none",
            "&:hover": {
                border: "none",
            },
        },
        // "@media (max-width: 767px)": {
        //     top: "142px",
        // },
    },
}))

export default useStyles
