import ResellerStore from "@/store/reseller"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useStyles from "./styles"

import logoDilypse from "@/assets/images/logo-dilypse.png"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import env from "@/config/env"
import MENU_ITEM_LIST from "@/data/menuItemList"
import useProfileInfo from "@/hooks/useProfileInfo"
import useWindowSize from "@/hooks/useWindowSize"
import { UserSessionService } from "@/services"
import UiStore from "@/store/ui"
import UserSessionStore from "@/store/user-session"
import { MenuItemType } from "@/types/menu"
import { googleLogout } from "@react-oauth/google"
import classNames from "classnames"
import { useCallback, useEffect, useMemo, useState } from "react"
import useResponsive from "@/hooks/use-responsive"
const getMenu = (position, menu, isAdminCompany) => {
    return menu.filter(
        (item) => item.position === position && ((item.icon != "company" && !isAdminCompany) || isAdminCompany)
    )
}

const getClassName = (href: string) => {
    return classNames("menu-item", location.pathname.includes(href) && "active", !href && "parent")
}

const MenuButton = ({ label, icon, href, onClick }) => {
    const { t } = useTranslation()
    return (
        <Button
            key={label}
            className={getClassName(href)}
            onClick={onClick}
            endIcon={!href && <Icon name="chevronRight" />}
        >
            <Stack flexDirection={"row"} gap={1}>
                <Icon name={icon} />
                <Typography component={"span"} lineHeight={"normal"}>
                    {t(label)}
                </Typography>
            </Stack>
        </Button>
    )
}

const TopToolbar = ({ onClose }) => {
    const { resellerLogoDark, resellerName } = ResellerStore()
    const { t } = useTranslation()
    return (
        <Box className="logo-wrapper">
            <img src={resellerLogoDark || logoDilypse} alt={resellerName || "Logo"} />
            <CustomTooltip title={t("DASHBOARD.MENU_CLOSE")}>
                <IconButton onClick={() => onClose()}>
                    <Icon name="x" />
                </IconButton>
            </CustomTooltip>
        </Box>
    )
}

const CustomAvatar = () => {
    return (
        <Stack
            sx={{
                backgroundColor: "#5B7DD81F",
                borderRadius: "40px",
                width: "40px",
                height: "40px",
                justifyContent: "center",
                flex: "none",
                alignItems: "center",
            }}
        >
            <Icon name="userSvg" />
        </Stack>
    )
}

const ProfileUser = () => {
    const { profile } = UserSessionStore()
    return (
        <CustomTooltip
            title={
                <>
                    {profile?.firstname} {profile?.lastname}
                    <br />
                    {profile?.email}
                </>
            }
            followCursor
        >
            <Box className="profile-wrapper">
                <CustomAvatar />
                <Box className="profile">
                    <Typography component="span" variant="subtitle1">
                        {profile?.firstname} {profile?.lastname}
                    </Typography>
                    <Typography component="span" variant="subtitle2">
                        {profile?.email}
                    </Typography>
                </Box>
            </Box>
        </CustomTooltip>
    )
}

const MenuListItems = ({ onClick, menu, items }) => {
    const { isAdminCompany } = useProfileInfo()

    return getMenu(menu, items, isAdminCompany).map((item) => (
        <MenuButton
            key={item.label}
            href={item.href}
            icon={item.icon}
            label={item.label}
            onClick={() => onClick(item)}
        />
    ))
}

const MenuContentbar = ({ onNext }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <Box flexDirection={"column"} sx={{ width: "100%" }}>
            <Box className="items-wrapper">
                <MenuListItems items={MENU_ITEM_LIST} menu="top" onClick={onNext} />
            </Box>
            <Box className="bottom" sx={{ position: "absolute", bottom: "110px", width: "100%" }}>
                <MenuListItems items={MENU_ITEM_LIST} menu="bottom" onClick={onNext} />
                <Button
                    onClick={async () => {
                        await UserSessionService.logout()
                        navigate("/login")
                        // ResellerService.clearReseller()
                        googleLogout()
                    }}
                    className="menu-item"
                >
                    <Icon name="logOut" sx={{ color: "#8f919c" }} />
                    <Typography component={"span"}>{t("DASHBOARD.LOGOUT")}</Typography>
                </Button>
            </Box>
        </Box>
    )
}

const MenuBackButton = ({ label, onClick }) => {
    const { t } = useTranslation()
    return (
        <Button key={label} className={"menu-previous"} onClick={onClick}>
            <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                <Icon name="chevronLeft" sx={{ color: "#8f919c" }} />
                <Typography component={"span"} lineHeight={"normal"}>
                    {t(label)}
                </Typography>
            </Stack>
        </Button>
    )
}

const BaseMenu = () => {
    const { showMenu, setShowMenu } = UiStore()
    const { classes } = useStyles()
    const navigate = useNavigate()
    const [parentActive, setParentActive] = useState<MenuItemType>(null)
    const [childList, setChildList] = useState<MenuItemType[]>([])
    const [openChild, setOpenChild] = useState(false)
    const { isMobile } = useResponsive()

    const onClose = useCallback(
        (isChild?: boolean) => {
            if (isChild) {
                setOpenChild(false)
            }
            setShowMenu(false)
        },
        [setShowMenu]
    )

    const onNext = useCallback(
        (item) => {
            if (item.href) {
                onClose(isMobile)
                navigate(item.href)
            } else {
                setParentActive(item)
                setOpenChild(true)
                setChildList(item.children)
            }
        },
        [isMobile, navigate, onClose]
    )

    const onPrevious = () => {
        setOpenChild(false)
    }

    const { pathname } = useLocation()

    const findActiveMenu = useCallback(() => {
        const selected = {
            isChildren: false,
            item: null,
        }
        for (const item of MENU_ITEM_LIST) {
            const children = item.children ?? []
            let breakLoop = false
            for (const child of children) {
                if (child.href === pathname) {
                    selected.item = item
                    selected.isChildren = true
                    breakLoop = true
                    break
                }
            }
            if (breakLoop) {
                break
            }
        }
        if (selected.isChildren && selected.item) {
            onNext(selected.item)
        }
    }, [onNext, pathname])

    useEffect(() => {
        findActiveMenu()
    }, [findActiveMenu])

    return (
        <Box>
            <Box
                className={classNames(classes.menuBackdrop, showMenu && "show")}
                onClick={(e) => {
                    e.preventDefault()
                    if (e.target === e.currentTarget) {
                        onClose()
                    }
                }}
            />
            <Stack className={classNames(classes.menuStack)}>
                <Box className={classNames(classes.menuWrapper, "box-init", showMenu && "show")} data-e2e="base-menu">
                    <Box className="top">
                        <TopToolbar onClose={onClose} />
                    </Box>
                    <Box className="bloc-menu">
                        <Box
                            sx={{
                                position: "absolute",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                                width: "calc(100% - 32px)",
                            }}
                        >
                            <Box>
                                <ProfileUser />
                            </Box>
                            <MenuContentbar onNext={onNext} />
                        </Box>
                        <Box className={classNames(classes.menuWrapper, classes.menuDrawer, openChild && "show")}>
                            <Box className="top">
                                <MenuBackButton label={parentActive?.label} onClick={() => onPrevious()} />
                            </Box>
                            <Box id={"children-menu"} className="bloc-menu" sx={{ width: "calc(100% - 32px)" }}>
                                <Box className="items-wrapper">
                                    <MenuListItems items={childList} menu="top" onClick={onNext} />
                                </Box>
                                <Box className="bottom">
                                    <MenuListItems items={childList} menu="bottom" onClick={onNext} />
                                    {parentActive?.label === "MENU.PARAMETER" && (
                                        <Typography className="version">Version {env.APP_VERSION}</Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}

export default BaseMenu
