import { SxProps, Theme } from "@mui/material"
import { makeStyles } from "tss-react/mui"

export const labelErrorSx = ({
    error = false,
    height,
    hidden,
}: {
    error: boolean
    height?: number
    hidden: boolean
}): SxProps => ({
    borderWidth: 2,
    borderColor: "transparent",
    borderStyle: "solid",
    ...(error && {
        borderColor: "error.main",
    }),
    height,
    cursor: "pointer",
    display: hidden ? "none" : "flex",
    "@media (min-width: 768px)": {
        height: height ? 346 : "auto",
    },
    "&:hover": {
        borderColor: error ? "error.main" : "divider",
        "& .prerequisites": {
            "& span": {
                color: "text.primary",
                transition: "color .6s ease-in-out",
            },
        },
        transition: "border-width .6s ease-in-out",
    },
})

export const logoSx: SxProps<Theme> = {
    padding: 2,
    width: "calc(100% - 16px)",
    maxWidth: 346 + 32,
    boxSizing: "border-box",
    "@media (min-width: 768px)": {
        width: "100%",
        p: 2,
    },
    "& .title-wrapper": {
        width: "100%",
        gap: 0,
        display: "flex",
        "& h2": {
            width: "fit-content",
            mx: "auto",
            whiteSpace: "nowrap",
        },
        "& .btn-x": {
            position: "absolute",
            top: "20px",
            right: "14px",
        },
    },
    "& .add-file": {
        display: "flex",
        gap: 1,
    },
    "& .error": {
        p: "3px 0px 0px 8px",
        fontSize: 12,
    },
    "& .drop-zone-two": {
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        flexDirection: "column",
        display: "flex",
        backgroundColor: "background.default",
        borderRadius: 1,
        maxWidth: 346,
        boxSizing: "border-box",
        gap: 1,
        color: "primary.main",
        margin: "auto",
        "& .add-or-drop": {
            display: "flex",
            gap: 1,
            p: 1,
            "& span": {
                textAlign: "center",
                wordBreak: "break-word",
            },
        },
        "& .image-type": {
            fontSize: 14,
            color: "action.active",
            letterSpacing: "0.17px",
        },
        "& .btn-txt": {
            color: "primary.main",
            textDecoration: "underline",
        },
        "& .image-plus": {
            p: 1.5,
        },
    },
    "& .drop-zone": {
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        flexDirection: "column",
        backgroundColor: "background.default",
        borderRadius: 1,
        maxWidth: 346,
        height: 225,
        boxSizing: "border-box",
        gap: 1,
        color: "primary.main",
        "& .add-or-drop": {
            display: "flex",
            gap: 1,
            p: 1,
            "& span": {
                textAlign: "center",
                wordBreak: "break-word",
            },
        },
        "& .image-type": {
            fontSize: 14,
            textAlign: "center",
            color: "action.active",
        },
        "& .btn-txt": {
            color: "primary.main",
            textDecoration: "underline",
        },
        "& .image-plus": {
            p: 1.5,
        },
    },
    "& .action-wrapper": {
        "@media (min-width: 768px)": {
            flexDirection: "column",
        },
    },
}

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .inputs-wrapper": {
            height: "fit-content",
            "& .logo-wrapper": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                "@media (min-width: 768px)": {
                    flexDirection: "row",
                    gap: 16,
                },
                "& .image-frame": {
                    minWidth: 124,
                    width: 124,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(1, 128, 255, 0.12)",
                    borderRadius: 8,
                    gap: 8,
                    padding: 8,
                    "& span": {
                        color: theme.palette.primary.main,
                        textAlign: "center",
                    },
                    "&.without-logo": {
                        border: `2px solid ${theme.palette.error.main}`,
                        span: {
                            color: theme.palette.error.main,
                        },
                        svg: {
                            path: {
                                stroke: theme.palette.error.main,
                            },
                        },
                    },
                    "&.image-preview": {
                        backgroundColor: "transparent",
                        boxSizing: "border-box",
                        gap: 0,
                        padding: 0,
                        "& button": {
                            padding: 0,
                        },
                        "& > .MuiBox-root": {
                            width: "100%",
                            height: "100%",
                            borderRadius: 8,
                            position: "relative",
                            "&::before": {
                                content: '""',
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundColor: "rgba(53, 56, 75, 0.42)",
                                borderRadius: 8,
                                opacity: 0,
                                transition: "opacity 600ms ease-in-out",
                            },
                            "& img": {
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: 8,
                            },
                            "& svg": {
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                color: theme.palette.common.white,
                                opacity: 0,
                                transition: "opacity 600ms ease-in-out",
                            },
                            "&:hover": {
                                "&::before": {
                                    opacity: 1,
                                },
                                "& svg": {
                                    opacity: 1,
                                },
                            },
                        },
                    },
                },
            },
            "& .error": {
                textAlign: "center",
            },
            "& .wrapper": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                "& .title-icon": {
                    color: theme.palette.action.active,
                },
            },
            "& .has-adornment .MuiInputBase-root": {
                gap: 8,
                "& svg": {
                    margin: "auto",
                    marginBottom: 8,
                },
            },
            "& .switch-button-wrapper": {
                display: "flex",
                alignItems: "center",
                gap: 8,
                "& .MuiIconButton-root": {
                    padding: 0,
                },
                "& svg": {
                    color: theme.palette.action.active,
                },
            },
            "& .inputs": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                width: "100%",
                height: "fit-content",
                "@media (min-width: 768px)": {
                    gap: 16,
                    flexDirection: "row",
                    flexWrap: "wrap",
                },
                "& > *": {
                    "@media (min-width: 768px)": {
                        width: "calc(50% - 8px)",
                        "&:nth-of-type(3)": {
                            width: "100%",
                        },
                    },
                },
            },
            "&.address": {
                "& .title-wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    "@media (min-width: 768px)": {
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                    },
                },
                "& .inputs": {
                    "@media (min-width: 768px)": {
                        gap: 16,
                        flexDirection: "row",
                        flexWrap: "wrap",
                    },
                    "& > *": {
                        "@media (min-width: 768px)": {
                            width: "calc(50% - 8px)",
                            "&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)": {
                                width: "calc((100% - 32px) / 3)",
                            },
                        },
                    },
                    "&.half-each > *": {
                        "@media (min-width: 768px)": {
                            width: "calc(50% - 8px)",
                        },
                    },
                },
            },
        },
    },
}))

export default useStyles
