import Icon from "@/components/base/Icon"
import { IconButton, Stack, Typography } from "@mui/material"
import { Column } from "../custom-table"

type TableHeadSortOptionalProps = {
    onSort: (columnId: string) => void
}

type TableHeadSortProps = {
    label: string
    column: Column
    activeSort: string
} & Partial<TableHeadSortOptionalProps>

const TableHeadSort = ({ label, column, activeSort, onSort }: TableHeadSortProps) => {
    const sorted = activeSort === column.id

    return (
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
            <Typography>{label}</Typography>
            <IconButton
                onClick={() => {
                    if (typeof onSort === "function") {
                        if (activeSort === column.id) {
                            onSort(null)
                        } else {
                            onSort(column.id)
                        }
                    }
                }}
                sx={{
                    rotate: sorted ? "0" : "180deg",
                }}
            >
                <Icon name="arrowUp" />
            </IconButton>
        </Stack>
    )
}

export default TableHeadSort
