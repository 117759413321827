import { DispatchrService } from "@/api"
import { PostFile } from "@/api/reseller"
import { PostData } from "@/types/socials"
import { imgListExtension } from "@/utils/media"
import { getMediaRatio } from "@/utils/media-ratio"

class SocialsPostService {
    haveUnsavedDataPost(post: PostData) {
        if ((post?.description && post?.description !== "") || (post?.mediasLocal && post?.mediasLocal?.length > 0)) {
            return true
        }
        return false
    }

    formattedMedia(postMedia: PostFile) {
        const newMedias = []
        postMedia?.medias?.forEach((media) => {
            const metadata = getMediaRatio({
                naturalSize: { width: media.dimensions.width, height: media.dimensions.height },
            })
            const mediaExtension = media?.url?.split(".").pop().toLowerCase()
            newMedias.push({
                url: media.url,
                metadata,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
            })
        })
        return newMedias
    }

    hidePostAlert(locationIds: string[]) {
        return DispatchrService.put("location-state", {
            locationIds,
            showPostAlert: false,
        })
    }
}

export default new SocialsPostService()
