import useFormater from "@/hooks/useFormater"
import useNotification from "@/hooks/useNotification"
import EditModel from "@/pages/campaign/edit-model"
import { SuggestModel } from "@/pages/campaign/suggest-model"
import { EditModelService } from "@/services"
import CampaignStore from "@/store/campaign"
import UiStore from "@/store/ui"
import data from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import { Box, Button, Divider, FormLabel, IconButton, Popover, Stack, Typography } from "@mui/material"
import "quill-mention"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Icon from "../Icon"
import { IconAddModel } from "./icon-add-model"
import { PlaceholderQuill } from "./placeholder"
import useStyles, { menuSx } from "./style"
import { useInputEditor } from "./useInputEditor"

export interface IMention {
    id: number | string
    value: string
}

export default function QuillEditor({
    listReviews,
    hasModel,
    onChange,
    onCloseReviews,
    handleSubject,
    defaultValue,
    placeholder,
    className,
    theme,
    hashValues,
    atValues,
    toolbar,
    maxLength,
    hasNewUrl = false,
    editorType = "normal",
    label,
    descriptionBottom,
    feedback = false,
    type = "normal",
}: {
    listReviews?: any
    hasModel?: boolean
    onChange: (value: { html: string; delta: any; rawText: string; editorText: string }) => void
    onCloseReviews?: () => void
    handleSubject?: (subject: string) => void
    defaultValue?: string
    theme?: string
    placeholder?: string
    className?: string
    toolbar: any
    hashValues?: IMention[]
    atValues?: IMention[]
    maxLength?: number
    urlBtn?: boolean
    editorType?: "normal" | "email" | "sms"
    hasNewUrl?: boolean
    label?: string
    descriptionBottom?: string
    feedback?: boolean
    type?: "edition" | "normal"
}): JSX.Element {
    const { t } = useTranslation()
    const editorRef = useRef<any>()
    const refInputBloc = useRef<HTMLInputElement>(null)
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()
    const { businessSelect } = CampaignStore()
    const [isPreview, setIsPreview] = useState<boolean>(false)
    const [openEditModel, setOpenEditModel] = useState<boolean>(false)
    const [openSuggestModel, setOpenSuggestModel] = useState<boolean>(false)
    const [openModel, setOpenModel] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [langList, setLangList] = useState([])
    const { notif } = useNotification()
    const { translateString } = useFormater()

    const emptyValue = useMemo(() => {
        return !defaultValue || defaultValue === "<p><br></p>"
    }, [defaultValue])

    const {
        modules,
        handleChangeValue,
        toggleMenu,
        previewMessageEqualContent,
        handleChangeInput,
        open,
        anchorEl,
        handleClose,
        textLength,
        linkIsAdded,
        getMentionList,
    } = useInputEditor({ hashValues, atValues, maxLength, onChange, editorRef, toolbar, defaultValue })

    const showAlertLink = useMemo(() => {
        if (editorType === "email") return !linkIsAdded && !feedback

        return !linkIsAdded
    }, [linkIsAdded, feedback])

    const handleToggleModal = useCallback(() => {
        if (openModel) handleCloseModel()
        else setOpenModel(true)
    }, [openModel])

    const handleCloseModel = () => {
        setOpenModel(false)
    }

    const handleRefetchModel = useCallback(() => {
        onCloseReviews()
    }, [listReviews])

    const handleAddSuggest = useCallback(async () => {
        setLoading(true)
        for (const lang of langList) {
            const review = translateString(
                "CAMPAIGN.MODEL.TEMPLATE_LONG",
                lang === "FR" || lang === "BE" ? "fr-FR" : "en"
            )
            const titleReview = translateString(
                "CAMPAIGN.MODEL.TEMPLATE_TITLE",
                lang === "FR" || lang === "BE" ? "fr-FR" : "en"
            )
            const subjectReview = translateString(
                "CAMPAIGN.MODEL.TEMPLATE_SUBJECT",
                lang === "FR" || lang === "BE" ? "fr-FR" : "en"
            )
            if (review) {
                const response = await EditModelService.createReview(businessSelect.business, {
                    title: titleReview,
                    subject: subjectReview,
                    content: review,
                    lang: preferredLanguage,
                })
                if (response?.error) {
                    notif({ message: t("CAMPAIGN.MODEL.ERROR"), type: "ERROR" })
                } else {
                    if (langList.length === 1) {
                        handleChangeModel(review, subjectReview)
                    }
                }
            }
        }
        handleRefetchModel()
    }, [hasModel, listReviews, langList])

    const handleSelectLang = useCallback(
        (value: "FR" | "EN", checked: boolean) => {
            const listLangDefault = [...langList]
            if (value && checked && langList.find((lang) => lang == value) == undefined) {
                listLangDefault.push(value)
            }
            if (!checked && langList.find((lang) => lang == value)) {
                const index = listLangDefault.indexOf(value)
                if (index > -1) {
                    listLangDefault.splice(index, 1)
                }
            }
            setLangList(listLangDefault)
        },
        [langList]
    )

    const handleDrawerClose = useCallback(
        (testDefault) => {
            onCloseReviews()
            setOpenEditModel(false)
            setOpenSuggestModel(false)
            if (!openModel && testDefault) {
                handleToggleModal()
            }
        },
        [openModel, listReviews, langList]
    )

    useEffect(() => {
        if (!hasModel && openModel && !openEditModel) {
            handleToggleModal()
        }
        if (hasModel && openSuggestModel) {
            setLoading(false)
            setOpenSuggestModel(false)
            if (langList.length > 1) {
                handleToggleModal()
            }
            setLangList([])
        }
    }, [hasModel, listReviews, openModel])

    const handleNewReview = () => {
        setOpenSuggestModel(false)
        setOpenModel(false)
        setOpenEditModel(true)
    }

    const handleSuggestModel = useCallback(() => {
        if (openSuggestModel) handleCloseModel()
        else setOpenSuggestModel(true)
    }, [openSuggestModel])

    const handleChangeModel = useCallback(
        (content: string, subject: string) => {
            handleSubject(subject)
            handleChangeValue(content, null, null, editorRef.current.editor)
        },
        [handleSubject, handleChangeValue]
    )

    const classEditor = useMemo(() => {
        let text = ""
        if (openModel) {
            text = "open-model"
        }

        if (isPreview) {
            text = text + " preview"
        }
        return text
    }, [openModel, isPreview])

    const disableUrl = useMemo(() => {
        return defaultValue.includes("@url")
    }, [defaultValue])

    return (
        <Stack width="100%">
            <Box
                ref={refInputBloc}
                className={`w-100 h-full ${classes.quillBloc} ${className} ${disableUrl && "disable-url"}`}
                sx={{ position: "relative" }}
            >
                {label && (
                    <FormLabel required className="label-editor">
                        <Typography component={"span"} sx={{ color: "action.active" }}>
                            {label}
                        </Typography>
                    </FormLabel>
                )}
                <ReactQuill
                    ref={editorRef}
                    theme={theme}
                    className={classEditor}
                    value={defaultValue}
                    modules={{ ...modules }}
                    onChange={handleChangeValue}
                    onKeyDown={(e) => {
                        if (editorRef.current.editor) {
                            if (
                                editorRef.current.editor.getText().trim().length >= maxLength &&
                                !["Backspace", "ArrowDown", "ArrowUp", "ArrowRight", "ArrowLeft"].includes(e.key)
                            ) {
                                e.preventDefault()
                            }
                        }
                    }}
                    placeholder={placeholder}
                />

                {emptyValue && ["sms", "email"].includes(editorType) && (
                    <PlaceholderQuill
                        type={editorType}
                        editorRef={editorRef}
                        handleToggleModal={hasModel ? handleToggleModal : handleSuggestModel}
                    />
                )}

                <Stack className="section-emoji">
                    <Stack flexDirection={"row"} gap={1}>
                        {(editorType == "email" || editorType == "sms") && (
                            <IconAddModel
                                containerRef={refInputBloc}
                                listReviews={listReviews}
                                handleToggleModal={hasModel ? handleToggleModal : handleSuggestModel}
                                open={openModel}
                                handleClose={handleCloseModel}
                                setOpen={setOpenEditModel}
                                editorType={editorType}
                                handleChangeModel={handleChangeModel}
                                setIsPreview={setIsPreview}
                            />
                        )}
                        <IconButton onClick={toggleMenu} className="btn-x">
                            <Icon name="emoji" sx={{ fill: "none" }} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                if (!previewMessageEqualContent) {
                                    handleChangeInput("@", "text")
                                }
                            }}
                            className="btn-x"
                        >
                            <Icon name="atSign" sx={{ fill: "none" }} />
                        </IconButton>
                    </Stack>
                    {hasNewUrl && (
                        <>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ marginX: "8px", height: "24px", alignSelf: "center" }}
                            />

                            <Button
                                id="add-url-mention"
                                variant="text"
                                disabled={linkIsAdded}
                                sx={{ padding: 0 }}
                                onClick={() => {
                                    if (!previewMessageEqualContent) {
                                        handleChangeInput("url", "mention")
                                    }
                                }}
                            >
                                {t("CAMPAIGN.ADD_LINK")}
                            </Button>
                        </>
                    )}

                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        id={"basic-menu"}
                        sx={menuSx}
                    >
                        <Stack className="bloc-emoji">
                            <Picker
                                data={data}
                                locale={preferredLanguage.split("-")[0]}
                                onEmojiSelect={(e: any) => {
                                    handleChangeInput(e.native, "emoji")
                                }}
                            />
                        </Stack>
                    </Popover>
                </Stack>
            </Box>
            {((maxLength > 0 && editorRef?.current) || descriptionBottom) && (
                <Stack
                    flexDirection={"row"}
                    justifyContent={descriptionBottom ? "space-between" : "flex-end"}
                    sx={{ padding: "3px 14px" }}
                >
                    <Typography component="span" sx={{ color: "action.active" }}>
                        {descriptionBottom}
                    </Typography>
                    <Typography component="span" sx={{ color: "action.active" }}>
                        {textLength()}/{maxLength}
                    </Typography>
                </Stack>
            )}
            {showAlertLink && type === "normal" && (
                <Stack className={classes.missingLink}>
                    <Icon name="info" sx={{ color: "#2EAFF7" }} />
                    <Typography component={"span"} variant="body2" sx={{ color: "#014361" }}>
                        {t("CAMPAIGN.MISSING_LINK")}
                    </Typography>
                </Stack>
            )}
            {businessSelect && <EditModel open={openEditModel} onClose={() => handleDrawerClose(false)} />}

            {!hasModel && (
                <SuggestModel
                    open={openSuggestModel}
                    setOpen={handleNewReview}
                    onClose={() => handleDrawerClose(true)}
                    handleSelect={handleSelectLang}
                    addSuggestDefault={handleAddSuggest}
                    listReviews={listReviews}
                    loading={loading}
                    disable={langList?.length == 0 || loading}
                />
            )}
        </Stack>
    )
}
