import { HeaderWrapper } from "@/components/base/header-wrapper"
import ListContainer from "@/components/base/page-container/list-container"
import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import { AlertModalCreationBusiness } from "@/components/business/alert-creation"
import BusinessList from "@/components/business/list"
import { SkeletonBusinessList } from "@/components/skeleton/business/list"
import { SkeletonHeaderBtn } from "@/components/skeleton/header-btn-left-right"
import useLogic from "@/hooks/businesses/list/useLogic"
import { Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"

const ListBusiness = () => {
    const { t } = useTranslation()
    const {
        isAdminCompany,
        handleChangePage,
        metadata,
        setUiPageSession,
        items,
        searchProps,
        setSearchProps,
        alert,
        isLoading,
        openAlert,
        hanldeModalAlert,
        handleCheckLicence,
        loadingCheckLicence,
        fetchStatusLoading,
    } = useLogic()

    return (
        <ListContainer data-testid="list-businesss">
            {fetchStatusLoading ? (
                <Skeleton variant="rectangular" width={300} height={48} />
            ) : (
                <>
                    <TitleWrapper
                        pageIcon="profile"
                        title={t("BUSINESSES.TITLE")}
                        loading={fetchStatusLoading}
                        alert={alert}
                    />
                    <HeaderWrapper
                        buttonText={t("BUSINESSES.CREATE_BUTTON")}
                        onClickButton={() => {
                            handleCheckLicence()
                        }}
                        showActionButton={isAdminCompany}
                        withSearch={searchProps}
                        setWithSearch={setSearchProps}
                        loadingCheckLicence={loadingCheckLicence}
                    />
                </>
            )}

            <>
                {fetchStatusLoading && <SkeletonHeaderBtn />}
                {isLoading && <SkeletonBusinessList />}
            </>
            {!isLoading && !fetchStatusLoading && (
                <BusinessList list={items} metadata={metadata} handleChangePage={handleChangePage} />
            )}
            <AlertModalCreationBusiness
                open={openAlert}
                onClose={() => hanldeModalAlert(false)}
                onNext={() => setUiPageSession({ showPage: "create" })}
            />
        </ListContainer>
    )
}

export default ListBusiness
