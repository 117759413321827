import { CookiesService } from "@/services"
import { CategoryType, LocationType } from "@/types/businesses"
import { CompanyInfo } from "@/types/companies"
import { ApiService, DispatchrService } from "../"
import { ErrorResponse } from "../base"

export interface ResellerInfosResponse {
    vendor: {
        logo: string
        favicon: string
        logoDark: string
        name: string
        googleIdentityClientId: string
    }
}
export interface AttributeValues {
    value: boolean
    displayName: string
}

export interface AttributeType {
    meta: {
        gmb_id: string
    }
    label: string
    groupLabel: string
    type: string
    values: AttributeValues[]
    id: string
    publishedAt: null
}

export interface Metadata {
    total: number
    page: number
    per_page: number
    first_page: string
    previous_page: null
    next_page: null
    totalPage: number
}

export interface MediaMetaType {
    yext_id: string | null
    gmb_id: string | null
    facebook_id: string | null
    instagram_id: string | null
}

export interface MediaType {
    meta: MediaMetaType
    locationId: string
    url: string
    type: string
    category: string
    label: string
    provider: string[]
    state: string
    createdAt: string
    id: string
    thumbnailUrl: string
    updatedAt: string
    publishedAt: string
}

export interface MediaListResponse {
    metadata: Metadata
    data: MediaType[]
}

interface CategoryMetadata {
    total: number
}

export interface CategoryListResponse {
    metadata: CategoryMetadata
    data: CategoryType[]
}

interface ImageMeta {
    yext_id: null
    gmb_id: null
    facebook_id: null
    instagram_id: null
}

interface ImageTimestamp {
    date: string
    timezone_type: number
    timezone: string
}

export interface LocationImage {
    id: string
    meta: ImageMeta
    createdAt: ImageTimestamp
    updatedAt: ImageTimestamp
    locationId: string
    url: string
    thumbnailUrl: string
    type: string
    category?: string
    state?: string
    label: string
    provider: string[]
}

export interface SinglePostFileProps {
    location_id: string
    file: File
}

export interface MultiplePostFileProps {
    location_id: string
    files: File[]
}

export interface Dimensions {
    width: number
    height: number
}

export interface FileDetails {
    original_filename: string
    format: string
    filesize: number
    dimensions: Dimensions
    url: string
}

export interface PostFile {
    medias: FileDetails[]
    postS3Id: string
}

interface UpdateCompanyProps {
    company: CompanyInfo
    company_uid: string
    user_uid: string
}

export interface ServiceCategory {
    label: string
    description: string
    categoryId: string
    items: ServiceItem[]
    createdAt: Date
    id: string
}

export interface ServiceItem {
    label: string
    description: string | null
    price: number | null
    state: string
}

export interface ServiceCategoryListResponse {
    metadata: Metadata
    data: ServiceCategory[]
}

class ResellerApi {
    fetchResellerInfos = async (hostname: string): Promise<ResellerInfosResponse & Partial<ErrorResponse>> => {
        return await ApiService.get(`/reseller_infos`, {
            hostname,
            reseller_uid: CookiesService.get("resellerUid"),
        })
    }

    fetchCompanyInfo = async ({ id, user_uid }): Promise<CompanyInfo & Partial<ErrorResponse>> => {
        return await ApiService.get(`/company/${id}`, {
            user_uid,
        })
    }

    fetchLocationInfo = async ({
        uid,
        user_uid,
    }: {
        uid: string
        user_uid: string
    }): Promise<LocationType & Partial<ErrorResponse>> => {
        const data = await DispatchrService.get(`/location/${uid}?user_uid=${user_uid}`, {
            reseller_uid: CookiesService.get("resellerUid"),
        })
        return data
    }

    fetchLocationAttribute = async ({
        locationId,
        company_id,
        lang,
    }): Promise<AttributeType[] & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/location/attribute`, {
            _perPage: 0,
            locationId: locationId,
            company_id: company_id,
            lang: lang,
        })
    }

    updateCompany = async ({
        company,
        company_uid,
        user_uid,
    }: UpdateCompanyProps): Promise<CompanyInfo & Partial<ErrorResponse>> => {
        const body = {
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
            ...company,
        }
        return await ApiService.put(`/company/${company_uid}`, body)
    }

    // fetchCompagniesByUser: any = async (user_uid) => {
    //     return await ApiService.get(`/companies/user`, {
    //         user_uid,
    //     })
    // }

    postFile = async ({ location_id, file }: SinglePostFileProps): Promise<FileDetails & Partial<ErrorResponse>> => {
        return await DispatchrService.postFile(`/upload`, file, {
            location_id,
        })
    }

    postFiles = async ({
        location_id,
        files,
    }: MultiplePostFileProps): Promise<FileDetails[] & Partial<ErrorResponse>> => {
        return await DispatchrService.postFiles(`/upload`, files, {
            location_id,
        })
    }

    postFilesPost = async ({
        postS3Id,
        files,
    }: {
        postS3Id?: string
        files: File[]
    }): Promise<PostFile & Partial<ErrorResponse>> => {
        return await DispatchrService.postFiles(`/upload/post`, files, {
            postS3Id,
        })
    }

    postMedias = async (Medias: LocationImage[]): Promise<FileDetails[] & Partial<ErrorResponse>> => {
        return await DispatchrService.post(`/media`, Medias)
    }

    deleteMedia: any = async ({ idMedia }: { idMedia: string }): Promise<Partial<ErrorResponse>> => {
        return await DispatchrService.delete(`/media/${idMedia}`)
    }

    updateLocation = async (location: LocationType): Promise<LocationType & Partial<ErrorResponse>> => {
        return await DispatchrService.put(`/location/${location.id}`, location)
    }

    updateMedia = async ({ idMedia, payload }): Promise<LocationImage & Partial<ErrorResponse>> => {
        return await DispatchrService.put(`/media/${idMedia}`, payload)
    }

    getOneMedia = async ({ id }: { id: string }): Promise<LocationImage & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/media/${id}`, {})
    }

    getListMedia = async ({
        locationId,
        perpage,
    }: {
        locationId: string
        perpage: number
    }): Promise<MediaListResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/media`, {
            _perPage: perpage,
            locationId: locationId,
            _sortBy: "updatedAt",
            _sortOrder: -1,
            category: ["ADDITIONAL"],
        })
    }

    fetchPrimaryCategory = async ({
        id,
        lang,
    }: {
        id: string
        lang: string
    }): Promise<CategoryType & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/category/${id}`, {
            lang,
        })
    }

    fetchGoogleCategories = async ({
        lang,
        label,
    }: {
        lang: string
        label: string
    }): Promise<CategoryListResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/category`, {
            _perPage: 0,
            lang,
            "_text[label]": label,
        })
    }

    fetchSecondaryCategory = async ({
        ids,
        lang,
    }: {
        ids: string[]
        lang: string
    }): Promise<CategoryType[] & Partial<ErrorResponse>> => {
        return await DispatchrService.post(`/category`, {
            lang,
            ids,
            _perPage: 0,
        })
    }

    searchFacebookCategory = async ({
        searchText,
        lang,
    }: {
        searchText: string
        lang: string
    }): Promise<CategoryListResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/category`, {
            _perPage: 0,
            lang,
            "_text[label]": searchText,
            "_text[source]": "facebook",
        })
    }

    fetchServices = async ({ uid }: { uid: string }) => {
        return await DispatchrService.get(`/service`, { locationId: uid })
    }

    updateServices = async (uid: string, payload: any = {}) => {
        return await DispatchrService.put(`/service/${uid}`, payload)
    }

    createServices = async (payload: any = {}) => {
        return await DispatchrService.post(`/service`, payload)
    }

    disconnectFacebook: any = async (locationId: string, companyId: string) => {
        return await DispatchrService.delete(`/_entity/fb_access/${companyId}/${locationId}`)
    }

    disconnectInstagram: any = async (locationId: string) => {
        return await DispatchrService.delete(`/_entity/ig_access/${locationId}`)
    }

    checkLicence = async ({ uid, reseller_uid }: { uid: string; reseller_uid: string }) => {
        return await ApiService.get(`/companies_has_price?user_uid=${uid}&reseller_uid=${reseller_uid}`)
    }

    getGBPAccount = async ({ company_uid }: { company_uid: string }) => {
        return ApiService.get(`/gbp-account`, {
            company_uid,
        })
    }
}

export default new ResellerApi()
