import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    tableWrapper: {
        margin: 0,
        overflow: "visible",
        height: "auto",
        "& .MuiTablePagination-displayedRows": {
            color: theme.palette.text.primary,
        },
        "& .MuiTablePagination-actions": {
            color: theme.palette.action.active,
            "& button": {
                padding: 12,
            },
        },
        "& .list-action-wrapper": {
            padding: "0 8px",
            display: "flex",
            gap: 8,
            justifyContent: "end",
            "@media (max-width: 767px)": {
                padding: 0,
            },
            "& button": {
                padding: 0,
                minWidth: 0,
                height: "fit-content",
                margin: "auto 0",
                width: "fit-content !important",
                "&.edit-btn": {
                    color: theme.palette.primary.main,
                },
            },
        },
        "& .custom-header": {
            height: "32px",
            background: "#F4F5FA",
            boxShadow: "rgba(8, 0, 31, 0.18) 0px 1px 2px -1px",
            borderRadius: "10px",
            "& .MuiTableCell-root": {
                padding: "0px 10px",
                borderBottom: "none",
                "&:last-child": {
                    borderRadius: "0px 10px 10px 0px",
                },
                "&:first-of-type": {
                    borderRadius: "10px 0px 0px 10px",
                },
            },
        },
        "& tbody": {
            "& .edited-cell": {
                width: "calc(16px)",
                margin: "8px 0px",
                "@media (max-width: 767px)": {
                    width: "100%",
                    margin: 0,
                },
            },
            "& .MuiTableCell-root": {
                borderBottom: "1px solid " + theme.palette.divider,
                color: theme.palette.text.primary,
                padding: 0,
                "@media (max-width: 767px)": {
                    "&.no-border": {
                        border: "none",
                    },
                },
                "& .MuiTextField-root ": {
                    width: "calc(100% - 8px)",
                    "@media (max-width: 767px)": {
                        width: "100%",
                    },
                },
                // display: "flex",
                "& button": {
                    fontSize: 16,
                    color: theme.palette.text.primary,
                    justifyContent: "flex-start",
                    borderRadius: 0,
                    textAlign: "start",
                    wordBreak: "break-word",
                    "&.btn-info": {
                        width: "100%",
                    },
                },
                "& .btn": {
                    padding: 10,
                    borderRadius: "10px",
                    gap: 8,
                    "&.btn-cancel": {
                        color: theme.palette.primary.main,
                        background: "rgba(1, 128, 255, 0.12)",
                        border: "none",
                    },
                    "&.btn-save": {
                        color: "white",
                    },
                },
            },
            "& .last-child td": {
                borderBottom: "none",
            },
            "& button": {
                boxShadow: "initial",
            },
        },
        "& tfoot": {
            "& td": {
                borderBottom: "none",
            },
            "& .table-pagination-actions-wrapper": {
                flexShrink: 0,
                marginLeft: 26,
                "& button": {
                    padding: 12,
                },
            },
        },
    },
}))

export default useStyles
