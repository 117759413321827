import { SocialsApi } from "@/api"
import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import TableSocialCheck from "../../table-social-check"

export const ErrorPostDetails = () => {
    const [items, setItems] = useState(null)
    const [searchParams] = useSearchParams()
    const postId = searchParams.get("edit-post")
    const [provider, setProvider] = useState([])

    const formatRows = useCallback((rows) => {
        const rowMap = {}
        const _provider = []

        for (const row of rows) {
            rowMap[row.locationId] ??= {
                name: row.locationName,
                address: `${row.locationAddress}, ${row.locationCity}`,
            }
            rowMap[row.locationId][row.provider] = row.status !== "FAILED"
            if (!_provider.includes(row.provider)) {
                _provider.push(row.provider)
            }
        }
        setProvider(_provider)
        return Object.values(rowMap)
    }, [])

    const getPostItems = useCallback(async () => {
        if (postId) {
            const resp = await SocialsApi.getPostItems(postId)
            const rows = formatRows(resp)
            setItems(rows)
        }
    }, [postId, formatRows])

    useEffect(() => {
        getPostItems()
    }, [getPostItems])

    return <TableSocialCheck rows={items} providers={provider} />
}
