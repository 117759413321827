import { SocialsApi } from "@/api"
import Icon from "@/components/base/Icon"
import { BtnDotsHorizontalDelete } from "@/components/btn-dots-horizontal-delete"
import usePageLogic from "@/hooks/social/usePageLogic"
import useResponsive from "@/hooks/use-responsive"
import useNotification from "@/hooks/useNotification"
import { getEtcGmtTimezone, getGmtOffset } from "@/utils/date"
import { Box, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ConfirmDelete } from "../modal/confirm-delete"
import { useStylesSinglePost } from "../style"
import SocialPopupStore from "@/store/social-popup"

const ViewSinglePost = ({
    description,
    imageCover,
    facebook,
    google,
    instagram,
    postId,
    onPostClick,
    status,
    date,
}) => {
    const { isMobile } = useResponsive()
    const { t } = useTranslation()
    const { notif } = useNotification()
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const { fetchPosts } = usePageLogic()
    const postHeight = isMobile ? "178px" : "150px"
    const { showPopupContent } = SocialPopupStore()
    const hasCover = useMemo(() => imageCover && imageCover !== undefined, [imageCover])
    const { classes } = useStylesSinglePost({ props: { hasCover, imageCover, postHeight, status } })
    const isAfterToday = useMemo(() => {
        const timezoneUser = getEtcGmtTimezone(getGmtOffset())
        const formattedDate = dayjs.utc(date).tz(timezoneUser).format("YYYY-MM-DDTHH:mm")

        return dayjs(formattedDate).isAfter(dayjs())
    }, [date])

    const handleRemove = useCallback(async () => {
        setLoading(true)
        const res = await SocialsApi.removePost(postId)
        if (res?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            await fetchPosts()
            notif({ message: t("SOCIALS.SUCCESS_DELETE_POST"), type: "SUCCESS" })
        }
        setShowModalDelete(false)
        setLoading(false)
    }, [postId, fetchPosts])

    return (
        <Stack className={classes.singlePost}>
            {["DRAFT", "LIVE_W_ERROR"].includes(status) && (
                <Stack
                    className="draft-title"
                    onClick={() => {
                        onPostClick(postId)
                    }}
                >
                    <Typography variant="caption" color="white">
                        {t(`SOCIALS.${status}`)}
                    </Typography>
                </Stack>
            )}
            <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                sx={{
                    padding: "4px",
                    ...(hasCover
                        ? {
                              background: "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 44.05%, rgba(0, 0, 0, 0) 100%)",
                              flexGrow: 1,
                          }
                        : {}),
                }}
            >
                <Stack
                    sx={{
                        gap: "4px",
                    }}
                    flexDirection={"row"}
                    className="header-content"
                    onClick={() => {
                        onPostClick(postId)
                    }}
                    width={"100%"}
                    height={"100%"}
                >
                    {google && (
                        <Icon
                            name="googleUniColor"
                            sx={{ color: hasCover ? "white" : "#68738D", width: 16, height: 16 }}
                        />
                    )}
                    {instagram && (
                        <Icon
                            name="instagramUniColor"
                            sx={{ color: hasCover ? "white" : "#68738D", width: 16, height: 16 }}
                        />
                    )}
                    {facebook && (
                        <Icon
                            name="facebookUniColor"
                            sx={{ color: hasCover ? "white" : "#68738D", width: 16, height: 15 }}
                        />
                    )}
                </Stack>
                {isAfterToday && (
                    <BtnDotsHorizontalDelete handleRemove={() => setShowModalDelete(true)} closeMenuDelete={true} />
                )}
            </Stack>
            <Stack
                height={"100%"}
                justifyContent={"flex-end"}
                onClick={() => {
                    onPostClick(postId)
                }}
            >
                <Box className="bloc-description">
                    <Typography
                        color="common.white"
                        variant="caption"
                        component={"p"}
                        sx={{
                            display: "-webkit-box",
                            ...(!hasCover
                                ? {
                                      color: "#00041F",
                                      maxHeight: "82px",
                                      overflow: "hidden",
                                  }
                                : {
                                      color: "#FFF",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      WebkitLineClamp: "1",
                                      WebkitBoxOrient: "vertical",
                                  }),
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
            </Stack>
            <ConfirmDelete
                open={showModalDelete}
                handleClose={() => {
                    setShowModalDelete(false)
                }}
                handleConfirm={handleRemove}
                isLoading={loading}
            />
        </Stack>
    )
}

export default ViewSinglePost
