import { StatisticsApi } from "@/api"
import { FetchKpiInput } from "@/api/statistics"
import { AnalyticsReputationData } from "@/types/statistic"
import { checkFormatOfDateMonthDay } from "@/utils"
import dayjs from "dayjs"
import { t } from "i18next"
import { ChartService } from ".."

interface PropsSerie {
    name?: string
    data: number[]
    type?: string
}

class StatisticReputationService {
    fetchKpiReputation = async (filterParams: FetchKpiInput) => {
        return await StatisticsApi.fetchKpiReview(filterParams)
    }

    resolveGraphsAverageGrade = (graph: AnalyticsReputationData, type: string, locale: string) => {
        let maxPeriod = 0
        const categories = []
        const pourcentages = []
        const totalCurrent = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? checkFormatOfDateMonthDay(locale) : "MMM YY"
        const average_grade = {
            name: t("REQUEST_OPINION.AVERAGE_GRADE".toUpperCase()),
            data: [],
            type: "column",
            color: "#FBC02C",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
            markers: {
                size: 6, // Set size to display markers
                lineType: "dotted", // Set lineType to "dotted" for dotted line markers
            },
        }

        period.forEach((element) => {
            categories.push(dayjs(element.date).locale(locale).format(format))
            average_grade.data.push(element.review_rating_average)
            totalCurrent.push(element.review_rating_average)
        })

        previousPeriod.forEach((element) => {
            let valueTotal = element.review_rating_average
            if (element.review_rating_average === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            } else if (element.review_rating_average !== 0) {
                maxPeriod = element.review_rating_average
            }

            total.data.push(valueTotal)
            pourcentages.push({
                review_rating_average: maxPeriod === 0 ? null : element.review_rating_average_variation,
                total: maxPeriod === 0 ? null : element.review_rating_average_variation,
            })
        })

        const maxYaxis = 5
        const minXaxis = 0
        const series: PropsSerie[] = [average_grade]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 4],
            curve: "straight",
            dashArray: [0, 8],
        }

        const graphs = {
            series,
            categories,
            pourcentages,
            totalCurrent,
            stroke,
            maxYaxis: maxYaxis > 0 ? maxYaxis : 5,
            minXaxis,
        }

        const yFormatters = [
            ChartService.createFormatter("review_rating_average", graphs, false),
            ChartService.createFormatter("total", graphs, false),
        ]

        return { ...graphs, yFormatters, tooltip: { enabledOnSeries: [0] } }
    }

    resolveGraphsAReviewTreated = (graph: AnalyticsReputationData, type: string, locale: string) => {
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? checkFormatOfDateMonthDay(locale) : "MMM YY"

        const review_treated = {
            name: `${t("REQUEST_OPINION.RESPONSE_RATE".toUpperCase())} (%)`,
            data: [],
            type: "column",
            color: "#F9A824",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
            markers: {
                size: 6, // Set size to display markers
                lineType: "dotted", // Set lineType to "dotted" for dotted line markers
            },
        }

        const pourcentages = []
        const totalCurrent = []

        let maxPeriod = 0
        period.forEach((element) => {
            categories.push(dayjs(element.date).locale(locale).format(format))
            review_treated.data.push(element.review_treated)
            totalCurrent.push(element.review_treated)
        })
        previousPeriod.forEach((element) => {
            let valueTotal = element.review_treated
            if (element.review_treated === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            } else if (element.review_treated !== 0) {
                maxPeriod = element.review_treated
            }

            total.data.push(valueTotal)
            pourcentages.push({
                review_treated: maxPeriod === 0 ? null : element.review_treated_variation,
                total: maxPeriod === 0 ? null : element.review_treated_variation,
            })
        })

        const maxYaxis = 90
        const series: PropsSerie[] = [review_treated]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 4],
            curve: "straight",
            dashArray: [0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis: maxYaxis > 0 ? maxYaxis : 5 }

        const yFormatters = [
            ChartService.createFormatter("review_treated", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters, tooltip: { enabledOnSeries: [0] } }
    }

    resolveGraphsReviewCount = (graph: AnalyticsReputationData, type: string, locale: string) => {
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? checkFormatOfDateMonthDay(locale) : "MMM YY"

        const review_count = {
            name: t("REQUEST_OPINION.NUMBER_REVIEWS".toUpperCase()),
            data: [],
            type: "column",
            color: "#F58F16",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
            markers: {
                size: 6, // Set size to display markers
                lineType: "dotted", // Set lineType to "dotted" for dotted line markers
            },
        }

        const pourcentages = []
        const totalCurrent = []

        let maxPeriod = 0
        period.forEach((element) => {
            categories.push(dayjs(element.date).locale(locale).format(format))
            review_count.data.push(element.review_count)
            totalCurrent.push(element.review_count)
        })

        previousPeriod.forEach((element) => {
            let valueTotal = element.review_count
            if (element.review_count === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            } else if (element.review_count !== 0) {
                maxPeriod = element.review_count
            }

            total.data.push(valueTotal)
            pourcentages.push({
                review_count: maxPeriod === 0 ? null : element.review_count_variation,
                total: maxPeriod === 0 ? null : element.review_count_variation,
            })
        })

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [review_count]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 4],
            curve: "straight",
            dashArray: [0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis: maxYaxis > 0 ? maxYaxis : 5 }

        const yFormatters = [
            ChartService.createFormatter("review_count", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters, tooltip: { enabledOnSeries: [0] } }
    }

    resolveGraphsFeedback = (graph: AnalyticsReputationData, type: string, locale: string) => {
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? checkFormatOfDateMonthDay(locale) : "MMM YY"

        const feedback_sms = {
            name: t("REQUEST_OPINION.SMS".toUpperCase()),
            data: [],
            type: "column",
            color: "#F87E0D",
        }
        const feedback_email = {
            name: t("REQUEST_OPINION.EMAIL".toUpperCase()),
            data: [],
            type: "column",
            color: "#FFA41B",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
        }

        const pourcentages = []
        const totalCurrent = []
        let maxPeriod = 0

        period.forEach((element) => {
            categories.push(dayjs(element.date).locale(locale).format(format))
            feedback_sms.data.push(element.feedback_sms)
            feedback_email.data.push(element.feedback_email)
            totalCurrent.push(element.feedback_total)
        })

        previousPeriod.forEach((element) => {
            let valueTotal = element.feedback_total
            if (element.feedback_total === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            }
            if (element.feedback_total !== 0) {
                maxPeriod = element.feedback_total
            }

            total.data.push(valueTotal)
            pourcentages.push({
                feedback_sms: maxPeriod === 0 ? null : element.feedback_sms_variation,
                feedback_email: maxPeriod === 0 ? null : element.feedback_email_variation,
                total: maxPeriod === 0 ? null : element.feedback_total_variation,
            })
        })

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [feedback_sms, feedback_email]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 0, 4],
            curve: "straight",
            dashArray: [0, 0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis: maxYaxis > 0 ? maxYaxis : 5 }

        const yFormatters = [
            ChartService.createFormatter("feedback_sms", graphs),
            ChartService.createFormatter("feedback_email", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters }
    }
}
export default new StatisticReputationService()
